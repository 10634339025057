import get from 'lodash/get'
import first from 'lodash/first'
import keys from 'lodash/keys'
import isArray from 'lodash/isArray'
import map from 'lodash/map'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import isNil from 'lodash/isNil'
import unionBy from 'lodash/unionBy'

import {
  TOGGLE_GLOBAL_LAYER_LEGEND,
  SELECT_GLOBAL_LAYER,
  RECEIVE_GLOBAL_LAYER_LEGEND_BY_TYPE_ID,
  RECEIVE_GLOBAL_LAYER_LEGEND_BY_BASE_NAME,
  RECEIVE_GLOBAL_LAYERS_DELIVERABLE_TYPES,
  RECEIVE_GLOBAL_LAYER_LEGEND,
  TOGGLE_LEGEND,
  UPDATE_SEQUENTIAL_LEGEND,
  SET_HIGHLIGHTED_CATEGORY,
  TOGGLE_SEQUENTIAL_NULLS,
  TOGGLE_SELECTION,
  SET_FILTERED_FIELD_IDS,
  TOGGLE_VISIBILITY,
  TOGGLE_KEEP_SELECTION,
  RESET_GLOBAL_LEGEND,
  RECEIVE_GLOBAL_MAP_CENTROID
} from '../actions/globalLayer'
import { RECEIVE_GEOMETRIES } from '../actions/fields'
import { CATEGORICAL_COLORS, DAY_CYCLE_CAP, DEFAULT_LEGEND_TYPE, LAYERS_BASE_NAMES } from '../constants'
import { scaleSequential, scaleOrdinal } from 'd3-scale'
import { interpolateTurbo } from 'd3-scale-chromatic'

import { legendParser } from './helpers'
const join = (arr1, arr2) => {
  const sortedArr1 = arr1.sort((a, b) => +a.properties.gm_field_id - +b.properties.gm_field_id)
  const sortedArr2 = arr2.sort((a, b) => +a.id - +b.id)
  const joinedArray = []

  let i = 0
  let j = 0
  while (i < sortedArr1.length && j < sortedArr2.length) {
    if (sortedArr1[i].properties.gm_field_id === sortedArr2[j].id) {
      joinedArray.push({ centroid: sortedArr1[i].geometry, ...sortedArr1[i], ...sortedArr2[j] })
      i++
      j++
      continue
    }

    if (sortedArr1[i].properties.gm_field_id < sortedArr2[j].id) {
      joinedArray.push({ centroid: sortedArr1[i].geometry, ...sortedArr1[i] })
      i++
      continue
    }

    joinedArray.push({ ...sortedArr2[j] })
    j++
  }

  while (i < sortedArr1.length) {
    joinedArray.push({ centroid: sortedArr1[i].geometry, ...sortedArr1[i] })
    i++
  }

  while (j < sortedArr2.length) {
    joinedArray.push({ ...sortedArr2[j] })
    j++
  }
  return joinedArray
}

const initialState = {
  centroids: null,
  filteredFieldIds: [],
  selectFilteredFields: false,
  isGlobalLayerLegendOpen: true,
  legends: {},
  selectedLayer: DEFAULT_LEGEND_TYPE,
  highlightedCategory: null,
  keepSelection: false,
  filteredFeatures: [],
  geometries: []
}

const { CULTIVATION_DAYS, WATER_STRESS } = LAYERS_BASE_NAMES

export default function globalLayer(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_GEOMETRIES:
      // eslint-disable-next-line no-case-declarations
      const parsedGeometries = map(action.geometries, field => {
        const geometryInfo = get(field, 'geometry[0]')
        const name = get(field, 'name')
        return {
          name,
          ...geometryInfo
        }
      })
      // eslint-disable-next-line no-case-declarations
      const allGeometries = unionBy(parsedGeometries, state, 'id')
      return {
        ...state,
        geometries: allGeometries
      }

    case RECEIVE_GLOBAL_MAP_CENTROID: {
      const { data } = action
      const parsedCentroids = data.map(item => {
        const geojson = JSON.parse(item.geojson)
        const geometry = JSON.parse(geojson.geometry)
        const properties = JSON.parse(geojson.properties)
        return {
          geometry,
          properties
        }
      })
      // remove duplicated using properties.gm_field_id
      const nonDuplicates = parsedCentroids.reduce((acc, current) => {
        const x = acc.find(item => item.properties.gm_field_id === current.properties.gm_field_id)
        if (!x) {
          return acc.concat([current])
        } else {
          return acc
        }
      }, [])

      const globalLayers = join(nonDuplicates, state.geometries)
      return {
        ...state,
        centroids: {
          type: 'FeatureCollection',
          features: nonDuplicates
        },
        globalLayers: {
          type: 'FeatureCollection',
          features: globalLayers
        }
      }
    }
    case TOGGLE_KEEP_SELECTION: {
      return {
        ...state,
        keepSelection: !state.keepSelection
      }
    }

    case RESET_GLOBAL_LEGEND: {
      const { legend } = action

      const isScaleFromDB = get(legend, 'isScaleFromDB')
      const isScaleTypeCategoric = get(legend, 'isScaleTypeCategoric')
      const newState = cloneDeep(state)
      if (!isScaleFromDB && !isScaleTypeCategoric) {
        const min = get(legend, 'legend.min')
        const max = get(legend, 'legend.max')

        set(newState, `legends.${legend.baseName}.legend.value`, [min, max])
        set(newState, `legends.${legend.baseName}.touched`, false)
        set(newState, `legends.${legend.baseName}.legend.showNulls`, true)
        return newState
      }

      const defaultValues = legend.legend.map(layer => ({ ...layer, visible: true }))
      set(newState, `legends.${legend.baseName}.legend`, defaultValues)
      set(newState, `legends.${legend.baseName}.touched`, false)

      return newState
    }

    case SET_FILTERED_FIELD_IDS: {
      const { filteredFieldIds, filteredFeatures } = action
      return {
        ...state,
        filteredFieldIds,
        filteredFeatures
      }
    }
    case TOGGLE_SELECTION: {
      const { selectFilteredFields } = action
      return {
        ...state,
        selectFilteredFields
      }
    }
    case TOGGLE_SEQUENTIAL_NULLS: {
      const { newValue, selectedLayer } = action
      const { legends } = state

      const newState = cloneDeep(state)

      const value = legends[selectedLayer].legend.value

      const min = legends[selectedLayer].legend.min
      const max = legends[selectedLayer].legend.max

      set(newState, `legends.${selectedLayer}.legend.showNulls`, newValue)
      if (!value) {
        set(newState, `legends.${selectedLayer}.touched`, !newValue)
      } else {
        if (value[0] === min && max === value[1]) {
          set(newState, `legends.${selectedLayer}.touched`, !newValue)
        } else {
          set(newState, `legends.${selectedLayer}.touched`, true)
        }
      }

      return newState
    }
    case SET_HIGHLIGHTED_CATEGORY: {
      const { highlightedCategory } = action
      return {
        ...state,
        highlightedCategory
      }
    }
    case UPDATE_SEQUENTIAL_LEGEND: {
      const { newValue, selectedLayer } = action

      const newState = cloneDeep(state)

      const min = get(newState, `legends.${selectedLayer}.legend.min`)
      const max = get(newState, `legends.${selectedLayer}.legend.max`)
      const showNulls = get(newState, `legends.${selectedLayer}.legend.showNulls`)
      const touched = newValue[0] !== min || newValue[1] !== max || showNulls === false

      set(newState, `legends.${selectedLayer}.legend.value`, newValue)
      set(newState, `legends.${selectedLayer}.touched`, touched)
      return newState
    }
    case TOGGLE_LEGEND: {
      const { legend } = action
      const { legends, selectedLayer } = state

      const legendToUpdate = legends[selectedLayer].legend

      const updatedLegend = map(legendToUpdate, l => {
        if (l.value || l.value === null) {
          if (l.value === legend.value)
            return {
              ...l,
              visible: !l.visible
            }
          return l
        }
        if (l.id === legend.id) {
          return {
            ...l,
            visible: !l.visible
          }
        }
        return l
      })

      const touched = updatedLegend.some(l => l.visible !== true)

      return {
        ...state,
        legends: {
          ...legends,
          [selectedLayer]: {
            ...legends[selectedLayer],
            touched,
            legend: updatedLegend
          }
        }
      }
    }

    case TOGGLE_GLOBAL_LAYER_LEGEND: {
      return {
        ...state,
        isGlobalLayerLegendOpen: !state.isGlobalLayerLegendOpen
      }
    }

    case RECEIVE_GLOBAL_LAYER_LEGEND_BY_BASE_NAME: {
      const { legend } = action
      if (!legend) return state
      const deliverableType = first(legend)
      const baseName = get(deliverableType, 'basefilename')
      const values = get(deliverableType, 'values')
      const parsedLegend = legendParser(values)
      // add values to existing baseName legend
      if (state.legends[baseName]) {
        return {
          ...state,
          legends: {
            ...state.legends,
            [baseName]: {
              ...state.legends[baseName],
              legend: parsedLegend
            }
          }
        }
      }
      return state
    }

    case RECEIVE_GLOBAL_LAYER_LEGEND_BY_TYPE_ID: {
      const { title, legend } = action
      if (!title || !legend) return state
      const deliverableType = first(title)
      const deliverableTypeId = get(deliverableType, 'id')
      const baseName = get(deliverableType, 'basefilename')
      const name = get(deliverableType, 'name')
      return {
        ...state,
        legends: {
          ...state.legends,
          [baseName]: {
            name,
            baseName,
            touched: false,
            isScaleFromDB: true,
            isScaleTypeCategoric: true,
            legend: map(legend, value => ({ ...value, visible: true })),
            id: deliverableTypeId
          }
        }
      }
    }

    case RECEIVE_GLOBAL_LAYERS_DELIVERABLE_TYPES: {
      const { globalLayersTypes } = action
      const legends = globalLayersTypes.reduce((acc, deliverableType) => {
        const deliverableTypeId = get(deliverableType, 'id')
        const baseName = get(deliverableType, 'basefilename')
        const name = get(deliverableType, 'name')
        return {
          ...acc,
          [baseName]: {
            name,
            baseName,
            legend: null,
            touched: false,
            isScaleFromDB: true,
            isScaleTypeCategoric: true,
            id: deliverableTypeId
          }
        }
      }, {})
      return {
        ...state,
        legends: {
          ...state.legends,
          ...legends
        }
      }
    }

    case RECEIVE_GLOBAL_LAYER_LEGEND: {
      const { legend } = action
      // parse legend to match expected format
      const baseFileNames = keys(legend)
      const parsedLegends = baseFileNames.reduce((acc, baseFileName) => {
        let values = legend[baseFileName]
        const isScaleTypeCategoric = isArray(values)

        let scale = null
        // static scales can be cathegoric or continous
        if (isScaleTypeCategoric) {
          scale = scaleOrdinal()
            .domain(values.map(l => l.value))
            .range(CATEGORICAL_COLORS)

          return {
            ...acc,
            [baseFileName]: {
              legend: [{ value: null, visible: true }, ...map(values, value => ({ value, visible: true }))],
              isScaleFromDB: false,
              isScaleTypeCategoric,
              touched: false,
              baseName: baseFileName,
              name: baseFileName,
              id: baseFileName,
              scale
            }
          }
        }

        const min = get(values, 'min')
        const max = get(values, 'max')

        // ugly business hack: max value for day cycle is capped at DAY_CYCLE_CAP
        const realMax = baseFileName === CULTIVATION_DAYS ? (max > DAY_CYCLE_CAP ? DAY_CYCLE_CAP : max) : max

        if (!isNil(min) && !isNil(max)) scale = null
        // change scale
        if (baseFileName === WATER_STRESS) {
          scale = scaleSequential(interpolateTurbo).domain([min, realMax])
        } else {
          scale = scaleSequential(interpolateTurbo).domain([realMax, min])
        }

        if (baseFileName === CULTIVATION_DAYS) {
          values = {
            ...values,
            max: realMax
          }
        }

        return {
          ...acc,
          [baseFileName]: {
            legend: { ...values, showNulls: true, value: [min, realMax] },
            isScaleFromDB: false,
            isScaleTypeCategoric,
            touched: false,
            baseName: baseFileName,
            name: baseFileName,
            id: baseFileName,
            scale
          }
        }
      }, {})

      return {
        ...state,
        legends: {
          ...state.legends,
          ...parsedLegends
        }
      }
    }

    case SELECT_GLOBAL_LAYER: {
      const { keepSelection, selectedLayer: previousSelectedLayer } = state
      const { selectedLayer } = action

      if (!keepSelection) {
        // return to defaults
        const previousLegend = get(state, `legends.${previousSelectedLayer}`)
        const isScaleFromDB = get(previousLegend, 'isScaleFromDB')
        const isScaleTypeCategoric = get(previousLegend, 'isScaleTypeCategoric')
        const newState = cloneDeep(state)
        if (!isScaleFromDB && !isScaleTypeCategoric) {
          const min = get(previousLegend, 'legend.min')
          const max = get(previousLegend, 'legend.max')
          set(newState, `legends.${previousSelectedLayer}.legend.value`, [min, max])
          set(newState, `legends.${previousSelectedLayer}.legend.showNulls`, true)
          set(newState, `legends.${previousSelectedLayer}.touched`, false)
          return { ...newState, selectedLayer }
        }
        const defaultValues = map(get(previousLegend, 'legend'), legend => ({ ...legend, visible: true }))
        set(newState, `legends.${previousSelectedLayer}.legend`, defaultValues)
        set(newState, `legends.${previousSelectedLayer}.touched`, false)

        return { ...newState, selectedLayer }
      }

      return {
        ...state,
        selectedLayer
      }
    }

    case TOGGLE_VISIBILITY: {
      const { toggle } = action
      const { legends, selectedLayer } = state
      const newState = cloneDeep(state)
      const updatedLegend = map(legends[selectedLayer].legend, l => ({ ...l, visible: toggle }))
      const touched = updatedLegend.some(l => l.visible !== true)

      set(newState, `legends.${selectedLayer}.legend`, updatedLegend)
      set(newState, `legends.${selectedLayer}.touched`, touched)
      return newState
    }
    default:
      return state
  }
}
