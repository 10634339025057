import { seti18n } from './locale'
import { fetchDeliverableTypes } from './deliverables'
import { fetchCustomers } from './users'
import {
  fetchFieldTypes,
  fetchIrrigationTypes,
  fetchFieldIds,
  fetchFilteredFields,
  fetchGeometries,
  fetchCustomerValues,
  fetchCutNumberValues,
  fetchFieldTypesValues,
  fetchSeasonLabelValues
} from './fields'

import { receiveAllData, receiveAllDashboardData } from './viewmode'
import {
  fetchDefaultGlobalLayerLegendByBaseName,
  fetchGlobalLayersDeliverableTypes,
  fetchGlobalLayerLegend,
  setFilteredFieldIds,
  fetchGlobalMapCentroids
} from './globalLayer'

import { fetchReportTypes, fetchReportStatuses } from './reports'

import userStoredData from '../userStoredData'
import { setRequestStart, setRequestEnd, fetchRequestWrapper } from './ui'
import { isUIRequestViewerDataInitializationSuccess } from '../selectors/ui'
import { fetchFieldSeasonParameters } from './seasons'
import { fetchCustomersDeliverables } from './customersDeliverables'
import { fetchFieldsTileOrder } from './fieldsTileOrder'
import { REQUEST_EOX_CAPABILITIES, RECEIVE_EOX_CAPABILITIES } from '@layers-frontend/commons/constants'
import { fetchSampleTypes } from './sampleTypes'
import { fetchSamplePrefix } from './config'

export const initializeLocale = () => seti18n(userStoredData.locale)
export const REQUEST_DASHBOARD_DATA_INITIALIZATION = 'REQUEST_DASHBOARD_DATA_INITIALIZATION'
export const REQUEST_VIEWER_DATA_INITIALIZATION = 'REQUEST_VIEWER_DATA_INITIALIZATION'

const eoxUrl = `${window.location.origin}/eox`

export const fetchEox = () =>
  fetchRequestWrapper({
    customRoute: eoxUrl,
    requestType: REQUEST_EOX_CAPABILITIES,
    responseParser: 'text',
    onSuccess: response => {
      return { type: RECEIVE_EOX_CAPABILITIES, eoxXML: response }
    }
  })
export const initializeViewer = () => dispatch => {
  dispatch(setRequestStart(REQUEST_VIEWER_DATA_INITIALIZATION))

  try {
    dispatch(fetchEox())
  } catch (error) {
    console.log("Couldn't fetch EOX capabilities")
  }
  Promise.all([dispatch(fetchDeliverableTypes()), dispatch(fetchCustomersDeliverables()), dispatch(fetchFieldIds())]).then(() =>
    Promise.all([
      dispatch(fetchGeometries()),
      dispatch(fetchGlobalLayerLegend()),
      dispatch(fetchGlobalMapCentroids()),
      dispatch(fetchGlobalLayersDeliverableTypes()),
      dispatch(fetchFieldTypes()),
      dispatch(fetchCustomers()),
      dispatch(fetchIrrigationTypes()),
      dispatch(fetchReportTypes()),
      dispatch(fetchReportStatuses()),
      dispatch(fetchFieldSeasonParameters()),
      dispatch(fetchFieldsTileOrder()),
      dispatch(fetchCustomerValues()),
      dispatch(fetchCutNumberValues()),
      dispatch(fetchFieldTypesValues()),
      dispatch(fetchSeasonLabelValues()),
      dispatch(fetchSampleTypes()),
      dispatch(fetchFieldIds()),
      dispatch(fetchSamplePrefix()),
      dispatch(fetchDefaultGlobalLayerLegendByBaseName()),
      dispatch(fetchFilteredFields())
    ]).then(() => {
      dispatch(setFilteredFieldIds())
      dispatch(receiveAllData())
      dispatch(setRequestEnd(REQUEST_VIEWER_DATA_INITIALIZATION))
    })
  )
}

export const initializeDashboard = () => (dispatch, getState) => {
  if (!isUIRequestViewerDataInitializationSuccess(getState())) {
    dispatch(initializeViewer())
  }
  dispatch(setRequestStart(REQUEST_DASHBOARD_DATA_INITIALIZATION))
  dispatch(receiveAllDashboardData())
  dispatch(setRequestEnd(REQUEST_DASHBOARD_DATA_INITIALIZATION))
}
