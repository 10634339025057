import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: { key: 'user', data: null, status: 'idle', error: null },
  userAccounts: { key: 'userAccounts', data: null, status: 'idle', error: null },
  userPolicies: { key: 'userPolicies', data: null, status: 'idle', error: null },
  accountPolicies: { key: 'accountPolicies', data: null, status: 'idle', error: null },
  organizations: { key: 'organizations', data: null, status: 'idle', error: null }
}

const accountsConfigSlice = createSlice({
  name: 'accountsConfig',
  initialState,
  reducers: {
    fetchDataStart(state, action) {
      const { key } = action.payload
      state[key].status = 'loading'
    },
    fetchDataSuccess(state, action) {
      const { key, payload } = action.payload
      state[key].status = 'succeeded'
      state[key].data = payload
      state[key].error = null
    },
    fetchDataFailure(state, action) {
      const { key, error } = action.payload
      state[key].status = 'failed'
      state[key].error = error
    }
    // to-do : no backend
    /*
    updateUserAccountsPolicies(state, action) {
      const { key, payload } = action.payload
      state[key].status = 'updated'
      state[key].data = payload
    }
    updatePolicies(state, action) {
      const { key, payload } = action.payload
      state[key].status = 'succeeded'
      state[key].data = payload
    },
    updateFavourites(state, action) {
      const { key, payload } = action.payload
      state[key].status = 'updated'
      state[key].data = state[key].data.map(account => ({
        ...account,
        modifiedPolicy: {
          ...account.modifiedPolicy,
          favourite_accounts: {
            ...account.modifiedPolicy.favourite_accounts,
            value: payload
          }
        }
      }))
    } */
  }
})

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure } = accountsConfigSlice.actions

export default accountsConfigSlice.reducer
