import { loader } from 'graphql.macro'
import { fetchGraphqlWrapper, fetchRequestWrapper, setRequestEnd } from './ui'
import get from 'lodash/get'
import {
  GLOBAL_LAYERS_FRONTEND_COLOR_STRATEGY,
  QUERIED_GLOBAL_LAYERS_PERMISSIONS,
  DEFAULT_LEGEND_TYPE,
  DAY_CYCLE_CAP,
  NO_VALUE
} from '@layers-frontend/commons/constants'
import {
  hasLegendsLoaded,
  getDefaultLayerId,
  getSelectedLayer,
  getFilteredFieldIds,
  getTouchedLayers,
  getGlobalMapFeatures,
  getSelectedLayerLegend,
  getKeepSelection
} from '../selectors/globalLayer'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import orderBy from 'lodash/orderBy'
import includes from 'lodash/includes'
import map from 'lodash/map'
import filter from 'lodash/filter'
import keys from 'lodash/keys'
import { getUserRoles, hasRoleOrderedFeatures } from '@layers-frontend/commons/store/selectors/user'
import difference from 'lodash/difference'
import isEmpty from 'lodash/isEmpty'
import { fetchFieldsByIds } from './fields'
import { getFieldsIds } from '../selectors/fields'
import { getConfigProjection } from '../selectors/config'
import { getToken } from '../userStoredData'

export const FETCH_GLOBAL_MAP_CENTROID = 'FETCH_GLOBAL_MAP_CENTROID'

export const TOGGLE_LEGEND = 'TOGGLE_LEGEND'

export const TOGGLE_GLOBAL_LAYER_LEGEND = 'TOGGLE_GLOBAL_LAYER_LEGEND'
export const SELECT_GLOBAL_LAYER = 'SELECT_GLOBAL_LAYER'

export const REQUEST_GLOBAL_LAYER_LEGEND = 'REQUEST_GLOBAL_LAYER_LEGEND'
export const RECEIVE_GLOBAL_LAYER_LEGEND = 'RECEIVE_GLOBAL_LAYER_LEGEND'

export const REQUEST_GLOBAL_LAYER_LEGEND_BY_TYPE_ID = 'REQUEST_GLOBAL_LAYER_LEGEND_BY_TYPE_ID'
export const RECEIVE_GLOBAL_LAYER_LEGEND_BY_TYPE_ID = 'RECEIVE_GLOBAL_LAYER_LEGEND_BY_TYPE_ID'

export const REQUEST_GLOBAL_LAYER_LEGEND_BY_BASE_NAME = 'REQUEST_GLOBAL_LAYER_LEGEND_BY_BASE_NAME'
export const RECEIVE_GLOBAL_LAYER_LEGEND_BY_BASE_NAME = 'RECEIVE_GLOBAL_LAYER_LEGEND_BY_BASE_NAME'

export const REQUEST_GLOBAL_LAYERS_DELIVERABLE_TYPES = 'REQUEST_GLOBAL_LAYERS_DELIVERABLE_TYPES'
export const RECEIVE_GLOBAL_LAYERS_DELIVERABLE_TYPES = 'RECEIVE_GLOBAL_LAYERS_DELIVERABLE_TYPES'

export const UPDATE_SEQUENTIAL_LEGEND = 'UPDATE_SEQUENTIAL_LEGEND'
export const SET_HIGHLIGHTED_CATEGORY = 'SET_HIGHLIGHTED_CATEGORY'

export const TOGGLE_SEQUENTIAL_NULLS = 'TOGGLE_SEQUENTIAL_NULLS'

export const TOGGLE_SELECTION = 'TOGGLE_SELECTION'
export const SET_FILTERED_FIELD_IDS = 'SET_FILTERED_FIELD_IDS'

export const TOGGLE_VISIBILITY = 'TOGGLE_VISIBILITY'
export const TOGGLE_KEEP_SELECTION = 'TOGGLE_KEEP_SELECTION'

export const RESET_GLOBAL_LEGEND = 'RESET_GLOBAL_LEGEND'

export const REQUEST_GLOBAL_MAP_CENTROID = 'REQUEST_GLOBAL_MAP_CENTROID'
export const RECEIVE_GLOBAL_MAP_CENTROID = 'RECEIVE_GLOBAL_MAP_CENTROID'

export const resetGlobalLegend = legend => dispatch => {
  dispatch({
    type: RESET_GLOBAL_LEGEND,
    legend
  })

  return dispatch(setFilteredFieldIds())
}

export const toggleKeepSelection = () => (dispatch, getState) => {
  const state = getState()
  const selectedLayer = getSelectedLayer(state)
  const touchedLayers = getTouchedLayers(state)

  touchedLayers.forEach(layer => {
    if (layer.baseName !== selectedLayer) {
      dispatch(resetGlobalLegend(layer))
    }
  })

  return dispatch({
    type: TOGGLE_KEEP_SELECTION
  })
}

export const toggleGlobalMapSelection = selectFilteredFields => (dispatch, getState) => {
  const state = getState()
  const fetchedFields = getFieldsIds(state)
  const fieldIds = getFilteredFieldIds(state)

  const unfetchedFields = difference(fieldIds, fetchedFields)
  if (!isEmpty(unfetchedFields)) {
    dispatch(fetchFieldsByIds(unfetchedFields))
  }
  return dispatch({
    type: TOGGLE_SELECTION,
    selectFilteredFields,
    fieldIds
  })
}

export const setFilteredFieldIds = () => (dispatch, getState) => {
  const state = getState()
  const features = getGlobalMapFeatures(state)
  const projection = getConfigProjection(state)
  const shouldOrderFeatures = hasRoleOrderedFeatures(state)
  const selectedLayerLegend = getSelectedLayerLegend(state)
  const touchedLegends = getTouchedLayers(state)
  const keepSelection = getKeepSelection(state)
  const selectedLayerKey = get(selectedLayerLegend, 'baseName')
  const selectedLayerLegendValues = get(selectedLayerLegend, 'legend')

  const sortedFeatures = shouldOrderFeatures ? orderBy(features, ['properties.gm_name'], 'desc') : features
  const isScaleFromDB = get(selectedLayerLegend, 'isScaleFromDB')
  const isScaleTypeCategoric = get(selectedLayerLegend, 'isScaleTypeCategoric')
  const scale = get(selectedLayerLegend, 'scale')
  const value = get(selectedLayerLegend, 'legend.value')
  const showNulls = get(selectedLayerLegend, 'legend.showNulls')
  const disabledLegends = []

  if (!isScaleFromDB && isScaleTypeCategoric) {
    map(selectedLayerLegendValues, legend => {
      if (!legend.visible) disabledLegends.push(legend.value)
    })
  }
  const selectedLayers = []

  const filteredFeatures = map(sortedFeatures, (feature, index) => {
    let fill = themeColors.lightGreySolid

    const fieldId = get(feature, ['properties', 'gm_field_id'])
    const selectedLayerValue = get(feature, ['properties', selectedLayerKey])
    // checks if the current feature has any properties that have been touched
    const filters = map(touchedLegends, touchedLegend => {
      const name = get(touchedLegend, 'baseName')
      const isScaleFromDB = get(touchedLegend, 'isScaleFromDB')
      const isScaleTypeCategoric = get(touchedLegend, 'isScaleTypeCategoric')
      const sequentialRange = get(touchedLegend, 'legend.value') // sequential
      const showNulls = get(touchedLegend, 'legend.showNulls') // sequential
      const legend = get(touchedLegend, 'legend')
      const touchedLegendValue = get(feature, ['properties', name])

      if (!isScaleFromDB && isScaleTypeCategoric) {
        const isInIndex = legend.findIndex(l => l.value === touchedLegendValue)
        return isInIndex !== -1 && legend[isInIndex].visible
      }
      if (!isScaleFromDB && !isScaleTypeCategoric) {
        if (showNulls && touchedLegendValue === null) return true
        if (!showNulls && touchedLegendValue === null) return false

        const maxSequentialValue =
          touchedLegendValue && name === 'gm_luvi_day_cycle' ? (touchedLegendValue > DAY_CYCLE_CAP ? DAY_CYCLE_CAP : touchedLegendValue) : touchedLegendValue

        return maxSequentialValue && sequentialRange && maxSequentialValue >= sequentialRange[0] && maxSequentialValue <= sequentialRange[1]
      }
      const colorIndex = legend.findIndex(legend => {
        const range = legend.range
        if (!touchedLegendValue && range === NO_VALUE) return true
        if (touchedLegendValue && range && touchedLegendValue >= range[0] && touchedLegendValue <= range[1]) {
          return true
        }
        return false
      })
      return colorIndex !== -1 && legend[colorIndex] && legend[colorIndex].visible
    })

    // if any are false, then the feature needs to be filtered
    const isFiltered = filters.some(f => f === false)

    if (isScaleFromDB) {
      const colorIndex = selectedLayerLegendValues?.findIndex(legend => {
        const range = legend.range
        if (!selectedLayerValue && range === NO_VALUE) return true
        if (selectedLayerValue && range && selectedLayerValue >= range[0] && selectedLayerValue <= range[1]) {
          return true
        }
        return false
      })
      const visible = selectedLayerLegendValues?.[colorIndex] && selectedLayerLegendValues[colorIndex].visible

      // for db legends
      // if value falls in a range that has visible set to false, remove it
      // or if keep selection is on, and there are any filtered values, the fature is not visible
      if (!visible || (isFiltered && keepSelection)) {
        return null
      } else {
        selectedLayers.push(fieldId)
        fill = colorIndex !== -1 ? selectedLayerLegendValues[colorIndex].color : themeColors.lightGreySolid
      }
    } else {
      const maxSelectedValue =
        selectedLayerValue && selectedLayerKey === 'gm_luvi_day_cycle'
          ? selectedLayerValue > DAY_CYCLE_CAP
            ? DAY_CYCLE_CAP
            : selectedLayerValue
          : selectedLayerValue

      fill = scale && maxSelectedValue ? scale(maxSelectedValue) : themeColors.lightGreySolid

      // for categorical legends
      // if value is included in the disabled legends, the feature is not visible
      // if it isnt, add field to selected layers
      if (disabledLegends.includes(maxSelectedValue) || (isFiltered && keepSelection)) {
        return null
      } else if (isScaleTypeCategoric) {
        selectedLayers.push(fieldId)
      }
      // for sequential legends
      // if value is not inside selected range, the feature is not visible
      // or if keep selection is on, and there are any filtered values, the fature is not visible
      if ((maxSelectedValue && value && (maxSelectedValue < value[0] || maxSelectedValue > value[1])) || (isFiltered && keepSelection)) {
        return null
      } else if (!isScaleTypeCategoric) {
        if (showNulls && maxSelectedValue === null) {
          selectedLayers.push(fieldId)
        } else if (maxSelectedValue !== null) {
          selectedLayers.push(fieldId)
        }
        if (!maxSelectedValue && !showNulls) {
          return null
        }
      }
    }
    if (!feature.geometry) {
      return null
    }
    return {
      index,
      field: { ...feature.properties },
      properties: { ...feature.properties },
      type: feature.type,
      style: {
        stroke: {
          width: 1,
          color: themeColors.yellow
        },
        fill: {
          color: fill
        }
      },
      projection,
      coordinates: feature.geometry.coordinates,
      geometry: feature.geometry
    }
  })

  return dispatch({
    type: SET_FILTERED_FIELD_IDS,
    filteredFieldIds: selectedLayers,
    filteredFeatures
  })
}

export const setHighlightedCategory = highlightedCategory => ({
  type: SET_HIGHLIGHTED_CATEGORY,
  highlightedCategory
})

export const toggleSequentialLegendNulls = newValue => (dispatch, getState) => {
  const state = getState()
  const selectedLayer = getSelectedLayer(state)
  dispatch({
    type: TOGGLE_SEQUENTIAL_NULLS,
    newValue,
    selectedLayer
  })
  return dispatch(setFilteredFieldIds())
}

export const updateSequentialLegend = newValue => (dispatch, getState) => {
  const state = getState()
  const selectedLayer = getSelectedLayer(state)

  dispatch({
    type: UPDATE_SEQUENTIAL_LEGEND,
    newValue,
    selectedLayer
  })
  return dispatch(setFilteredFieldIds())
}

export const toggleLegend = legend => dispatch => {
  dispatch({
    type: TOGGLE_LEGEND,
    legend
  })
  return dispatch(setFilteredFieldIds())
}

export const receiveGlobalLayerLegend = legend => ({
  type: RECEIVE_GLOBAL_LAYER_LEGEND,
  legend,
  receivedAt: Date.now()
})

const getGlobalLayerLegend = loader('../graphql/getGlobalLayerLegend.gql').loc.source.body

export const fetchGlobalLayerLegend = () => (dispatch, getState) =>
  dispatch(
    fetchGraphqlWrapper({
      requestType: REQUEST_GLOBAL_LAYER_LEGEND,
      query: getGlobalLayerLegend,
      onSuccess: ({ globalLegend }) => {
        const userRoles = getUserRoles(getState())
        const frontendFilteredGlobalLayersTypes = filter(GLOBAL_LAYERS_FRONTEND_COLOR_STRATEGY, ({ necessaryRoles }) => {
          if (!necessaryRoles || necessaryRoles.length === 0) return true
          const hasNecessaryRoles = necessaryRoles.some(role => userRoles.includes(role))
          return hasNecessaryRoles
        })

        const ids = map(frontendFilteredGlobalLayersTypes, 'id')
        const unfilteredLegend = get(globalLegend[0], 'legend')
        const filteredLegend = {}
        map(keys(unfilteredLegend), key => {
          if (ids.includes(key)) {
            filteredLegend[key] = unfilteredLegend[key]
          }
        })

        return receiveGlobalLayerLegend(filteredLegend)
      },
      onError: console.log
    })
  )

export const toggleGlobalLayerLegend = () => ({
  type: TOGGLE_GLOBAL_LAYER_LEGEND
})

export const toggleGlobalLayersVisibility = toggle => dispatch => {
  dispatch({
    type: TOGGLE_VISIBILITY,
    toggle
  })
  return dispatch(setFilteredFieldIds())
}

export const selectGlobalLayer = selectedLayer => (dispatch, getState) => {
  const state = getState()

  const isFrontendColorStrategy = includes(map(GLOBAL_LAYERS_FRONTEND_COLOR_STRATEGY, 'id'), selectedLayer)

  if (!isFrontendColorStrategy) {
    const selectedLayerHasLegendsLoaded = hasLegendsLoaded(selectedLayer)(state)
    if (!selectedLayerHasLegendsLoaded) dispatch(fetchGlobalLayerLegendByBaseName(selectedLayer))

    if (selectedLayerHasLegendsLoaded) {
      dispatch({
        type: SELECT_GLOBAL_LAYER,
        selectedLayer
      })
      return dispatch(setFilteredFieldIds())
    }
  }

  if (isFrontendColorStrategy) {
    dispatch({
      type: SELECT_GLOBAL_LAYER,
      selectedLayer
    })
    return dispatch(setFilteredFieldIds())
  }
}

export const receiveGlobalLayerLegendByTypeId = ({ title, legend }) => ({
  type: RECEIVE_GLOBAL_LAYER_LEGEND_BY_TYPE_ID,
  title,
  legend
})

const getGlobalLayerLegendByTypeIdQuery = loader('../graphql/getGlobalLayerLegendByTypeId.gql').loc.source.body

export const fetchDefaultGlobalLayerLegendByTypeId = () => (dispatch, getState) => {
  const defaultLayerId = getDefaultLayerId(getState()) || DEFAULT_LEGEND_TYPE
  return dispatch(fetchGlobalLayerLegendByTypeId(defaultLayerId))
}

export const fetchGlobalLayerLegendByTypeId = typeId => dispatch =>
  dispatch(
    fetchGraphqlWrapper({
      requestType: REQUEST_GLOBAL_LAYER_LEGEND_BY_TYPE_ID,
      query: getGlobalLayerLegendByTypeIdQuery,
      variables: { typeId },
      onSuccess: ({ title, legend }) => {
        return receiveGlobalLayerLegendByTypeId({ title, legend })
      }
    })
  )

export const receiveGlobalLayerLegendByBaseName = legend => dispatch => {
  dispatch({
    type: SELECT_GLOBAL_LAYER,
    selectedLayer: legend[0].basefilename
  })
  dispatch({
    type: RECEIVE_GLOBAL_LAYER_LEGEND_BY_BASE_NAME,
    legend
  })
  return dispatch(setFilteredFieldIds())
}

const getGlobalLayerLegendByBaseNameQuery = loader('../graphql/getGlobalLayerLegendByBaseName.gql').loc.source.body

export const fetchDefaultGlobalLayerLegendByBaseName = () => dispatch => {
  const defaultLayer = DEFAULT_LEGEND_TYPE
  return dispatch(fetchGlobalLayerLegendByBaseName(defaultLayer))
}

export const fetchGlobalLayerLegendByBaseName = basefilename => dispatch =>
  dispatch(
    fetchGraphqlWrapper({
      requestType: REQUEST_GLOBAL_LAYER_LEGEND_BY_BASE_NAME,
      query: getGlobalLayerLegendByBaseNameQuery,
      variables: { basefilename },
      onSuccess: ({ legend }) => {
        return receiveGlobalLayerLegendByBaseName(legend)
      }
    })
  )

const getGlobalLayersDeliverableTypesQuery = loader('../graphql/getGlobalLayersDeliverableTypes.gql').loc.source.body

export const receiveGlobalLayersDeliverableTypes = globalLayersTypes => (dispatch, getState) => {
  const state = getState()
  const userRoles = getUserRoles(state)

  // filter globalLayersTypes by user roles.
  // search in QUERIED_GLOBAL_LAYERS_PERMISSIONS for each layer where globalLayerType basefilename matches id from QUERIED_GLOBAL_LAYERS_PERMISSIONS
  // if necessaryRoles is empty, then the element is always shown
  // if role is not found inside necessaryRoles inside QUERIED_GLOBAL_LAYERS_PERMISSIONS, then the globalLayerType is not shown
  const filteredGlobalLayersTypes = globalLayersTypes.filter(({ basefilename }) => {
    const queriedGlobalLayerPermission = QUERIED_GLOBAL_LAYERS_PERMISSIONS.find(({ id }) => id === basefilename)
    // if it's not on the list, it can be shown
    if (!queriedGlobalLayerPermission) return true
    const { necessaryRoles } = queriedGlobalLayerPermission
    if (!necessaryRoles || necessaryRoles.length === 0) return true
    return necessaryRoles.some(role => userRoles.includes(role))
  })

  // for now we are only deploying queried global layers, not frontend handled ones
  return dispatch({
    type: RECEIVE_GLOBAL_LAYERS_DELIVERABLE_TYPES,
    globalLayersTypes: filteredGlobalLayersTypes
  })
}

const fetchCentroids = ({ authorizingRole = null, overlay = false, requestType, returnType, mode, nextToken, queryId, nextQueryData = [], dispatch }) => {
  const baseCustomRoute = `${window.location.origin}/athena/global_maps`
  const body = nextToken
    ? { mode, next_token: nextToken, api_key: getToken(), query_id: queryId }
    : {
        api_key: getToken(),
        mode
      }
  return fetchRequestWrapper({
    customRoute: `${baseCustomRoute}?${new URLSearchParams({
      api_key: getToken()
    })}`,
    fetchOptions: {
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
      method: 'POST'
    },
    requestType,
    authorizingRole,
    overlay,
    hasNextPage: !!nextToken,
    onSuccess: data => {
      if (data.next_token) {
        return fetchCentroids({
          authorizingRole,
          overlay,
          requestType,
          returnType,
          mode,
          nextToken: data.next_token,
          apiKey: getToken(),
          queryId: data.query_id,
          nextQueryData: [...data.data, ...nextQueryData],
          dispatch
        })
      }
      dispatch(setRequestEnd(requestType))
      return { type: returnType, data: [...data.data, ...nextQueryData] }
    }
  })
}

export const fetchGlobalMapCentroids = () => dispatch => {
  dispatch(
    fetchCentroids({
      mode: 'centroid',
      requestType: FETCH_GLOBAL_MAP_CENTROID,
      returnType: 'RECEIVE_GLOBAL_MAP_CENTROID',
      overlay: true,
      dispatch
    })
  )
}

export const fetchGlobalLayersDeliverableTypes = () => dispatch =>
  dispatch(
    fetchGraphqlWrapper({
      requestType: REQUEST_GLOBAL_LAYERS_DELIVERABLE_TYPES,
      query: getGlobalLayersDeliverableTypesQuery,
      onSuccess: ({ globalLayersTypes }) => {
        return receiveGlobalLayersDeliverableTypes(globalLayersTypes)
      }
    })
  )
