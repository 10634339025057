import { loader } from 'graphql.macro'

import moment from 'moment'
import { openSearchMode, SEARCH_VIEW } from './viewmode'
import { errorNotification } from './notifications'
import { LANGS } from '../constants'
import { getConfigData } from '../selectors/config'
import { getScreenView } from '../selectors/viewmode'
import { getRealtimeDeliverableUrlWithAuth } from '../selectors/deliverablesForms'
import { fetchGraphqlWrapper, fetchRequestWrapper, setRequestStart, setRequestEnd, setRequestFail } from './ui'
import { getUserName, hasUserRole } from '../selectors/users'
import {
  getSelectedFields,
  getSelectedFieldsIds,
  getSelectedFieldsLength,
  isSingleFieldSelected,
  getFieldsIds,
  getGeometriesByFields,
  getFieldCustomerById
} from '../selectors/fields'
import {
  DELIVERABLE_FORMATS,
  DRONE_RGB_SNAPSHOT_SOURCE,
  DRONE_SNAPSHOT_SOURCE,
  APPLICATION_JSON_HEADER,
  USER_ROLES,
  CHUNK_SIZE
} from '@layers-frontend/commons/constants'

import { saveAs } from 'file-saver'
import { fetchActiveFieldsSamples } from './samples'
import { fetchSelectedFieldsSeasonsByDateRange, RECEIVE_FIELDS_SEASONS_BY_IDS } from './seasons'
import { getDeliverablesBySeasonIdAndSource } from '../selectors/seasons'
import { fetchSelectedFieldsFlights, fetchDBFlights } from './snapshots'
import { getSnapshots } from '../selectors/snapshots'
import { getDeliverableTypes } from '../selectors/deliverables'
import { clearVisibilities, toggleVisibility } from './deliverables'
import { getSelectedFlightGroupFirstVisibleDeliverable, getSelectedFlightGroupSeasonIds } from '../selectors/flightGroups'
import { formSeasonsFieldToRequest } from '../seasonFormUtils'
import { updateFieldAfterDeleting, updateFieldAndSeasons } from './fieldSeasonForm'
import { getDeliverablesBySource } from '../selectors/customersDeliverables'

import forIn from 'lodash/forIn'
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import reduce from 'lodash/reduce'
import sortBy from 'lodash/sortBy'
import unionBy from 'lodash/unionBy'
import { fetchSeasonLayersBySeasonIds } from './seasonLayers'
import values from 'lodash/values'
import includes from 'lodash/includes'
import { resetTempComparison, SET_TEMPORAL_COMPARISON_DATE, setActiveCalendarDate } from './calendar'

import { getTempComparisonDate, isCommonSourcesWithTemporalComparisonDate } from '../selectors/calendar'

import difference from 'lodash/difference'
import { getFieldStats } from '../selectors/fieldsStatistics'
import noop from 'lodash/noop'
import {
  getFiltersCropsIds,
  getFiltersCustomersIds,
  getFiltersCutNumber,
  getFiltersSearchInputString,
  getFiltersSeasonLabel,
  getFiltersPlantationDateRange
} from '../selectors/filters'

export const SELECT_FIELD = 'SELECT_FIELD'
export const SELECT_FIELDS = 'SELECT_FIELDS'
export const DESELECT_FIELDS = 'DESELECT_FIELDS'
export const DELETE_FIELD_FROM_LIST = 'DELETE_FIELD_FROM_LIST'
export const ADD_FLIGHT_GROUPS = 'ADD_FLIGHT_GROUPS'
export const SELECT_FLIGHTGROUP = 'SELECT_FLIGHTGROUP'

export const ADD_FIELDS_TO_GROUP = 'ADD_FIELDS_TO_GROUP'
export const SET_PREVIEW_FIELD_ID = 'SET_PREVIEW_FIELD_ID'
export const RESET_PREVIEW_FIELD_ID = 'RESET_PREVIEW_FIELD_ID'

export const REMOVE_FIELD_TO_GROUP = 'REMOVE_FIELD_TO_GROUP'
export const FLUSH_FIELDS_GROUP = 'FLUSH_FIELDS_GROUP'
export const REQUEST_FILTERED_FIELDS = 'REQUEST_FILTERED_FIELDS'
export const REQUEST_FIELDS = 'REQUEST_FIELDS'
export const RECEIVE_FIELDS = 'RECEIVE_FIELDS'
export const RECEIVE_FIELDS_COUNT = 'RECEIVE_FIELDS_COUNT'
export const REQUEST_FIELDS_BY_ID = 'REQUEST_FIELDS_BY_ID'
export const UPDATE_CURRENT_CHUNK = 'UPDATE_CURRENT_CHUNK'
export const UPDATE_FIELDS_COUNT = 'UPDATE_FIELDS_COUNT'

export const REQUEST_GEOMETRIES = 'REQUEST_GEOMETRIES'
export const REQUEST_GEOMETRIES_BY_FIELD_IDS = 'REQUEST_GEOMETRIES_BY_FIELD_IDS'
export const RECEIVE_GEOMETRIES = 'RECEIVE_GEOMETRIES'

export const REQUEST_IRRIGATION_TYPES = 'REQUEST_IRRIGATION_TYPES'
export const RECEIVE_IRRIGATION_TYPES = 'RECEIVE_IRRIGATION_TYPES'

export const REQUEST_FIELD_TYPES = 'REQUEST_FIELD_TYPES'
export const RECEIVE_FIELD_TYPES = 'RECEIVE_FIELD_TYPES'
export const REQUEST_FIELD_TYPES_VALUES = 'REQUEST_FIELD_TYPES_VALUES'
export const RECEIVE_FIELD_TYPES_VALUES = 'RECEIVE_FIELD_TYPES_VALUES'
export const REQUEST_CUT_NUMBER_VALUES = 'REQUEST_CUT_NUMBER_VALUES'
export const RECEIVE_CUT_NUMBER_VALUES = 'RECEIVE_CUT_NUMBER_VALUES'
export const REQUEST_SEASON_LABEL_VALUES = 'REQUEST_SEASON_LABEL_VALUES'
export const RECEIVE_SEASON_LABEL_VALUES = 'RECEIVE_SEASON_LABEL_VALUES'
export const REQUEST_CUSTOMER_VALUES = 'REQUEST_CUSTOMER_VALUES'
export const RECEIVE_CUSTOMER_VALUES = 'RECEIVE_CUSTOMER_VALUES'

export const REQUEST_SATELLITE_FLIGHT_DATES = 'REQUEST_SATELLITE_FLIGHT_DATES'
export const RECEIVE_PLANET_FLIGHT_DATES = 'RECEIVE_PLANET_FLIGHT_DATES'
export const SELECT_SNAPSHOT = 'SELECT_SNAPSHOT'
export const SELECT_TEMPORAL_SNAPSHOT = 'SELECT_TEMPORAL_SNAPSHOT'
export const MOVE_TEMPORAL_SLIDER = 'MOVE_TEMPORAL_SLIDER'
export const SELECT_FIELD_IMAGE = 'SELECT_FIELD_IMAGE'
export const DESELECT_FIELD_IMAGE = 'DESELECT_FIELD_IMAGE'
export const SATELLITE_TYPE = 'SATELLITE_TYPE'
export const PLANET_TYPE = 'PLANET_TYPE'
export const DRONE_TYPE = 'DRONE_TYPE'
export const DRONE_RGB_TYPE = 'DRONE_RGB_TYPE'
export const PLANE_TYPE = 'PLANE_TYPE'
export const RADAR_TYPE = 'RADAR_TYPE'

export const REQUEST_DOWNLOAD_FIELDS_GEOJSON = 'REQUEST_DOWNLOAD_FIELDS_GEOJSON'
export const DOWNLOAD_FIELDS_GEOJSON_SUCCESS = 'DOWNLOAD_FIELDS_GEOJSON_SUCCESS'

const { RAW_DOWNLOAD } = DELIVERABLE_FORMATS
export const REQUEST_PATCH_FIELD_WITH_SEASONS = 'REQUEST_PATCH_FIELD_WITH_SEASONS'
export const REQUEST_POST_FIELD_WITH_SEASONS = 'REQUEST_POST_FIELD_WITH_SEASONS'
export const REQUEST_DELETE_FIELD = 'REQUEST_DELETE_FIELD'

const { ROLE_SH_CALENDAR } = USER_ROLES

// helper functions
const _addDeliverableTypeToDeliverable = (deliverable, deliverableTypes) => {
  const deliverableTypeId = get(deliverable, 'typeId')
  return {
    ...deliverable,
    type: get(deliverableTypes, deliverableTypeId)
  }
}

const _buildFlightDeliverables = (flight, state) => {
  const deliverableTypes = getDeliverableTypes(state)
  const seasons = get(flight, 'seasons')
  const seasonIds = map(seasons, 'id')
  const source = get(flight, 'source')
  const isSingleSelection = isSingleFieldSelected(state)

  const flightDate = get(flight, 'date')
  const basePdfGenerationUrl = getRealtimeDeliverableUrlWithAuth(state)
  const language = find(LANGS, lang => lang.includes(get(state, 'locale')))
  let flightDeliverables = {}
  switch (source) {
    case DRONE_SNAPSHOT_SOURCE:
    case DRONE_RGB_SNAPSHOT_SOURCE:
      // eslint-disable-next-line no-case-declarations
      const deliverables = get(flight, 'deliverables')
      flightDeliverables = reduce(
        deliverables,
        (accumulator, seasonDeliverables) => {
          const previousDeliverables = get(accumulator, 'deliverables', [])
          const previousPdfDeliverables = get(accumulator, 'pdfDeliverables', [])

          // add deliverable type to deliverables
          const newDeliverables = map(get(seasonDeliverables, 'deliverables'), deliverable => _addDeliverableTypeToDeliverable(deliverable, deliverableTypes))
          const newPdfDeliverables = map(get(seasonDeliverables, 'pdfDeliverables'), deliverable =>
            _addDeliverableTypeToDeliverable(deliverable, deliverableTypes)
          )

          const allDeliverables = unionBy(previousDeliverables, newDeliverables, 'type.id')
          const allPdfDeliverables = isSingleSelection && unionBy(previousPdfDeliverables, newPdfDeliverables, 'type.id')

          return {
            ...accumulator,
            deliverables: allDeliverables,
            pdfDeliverables: allPdfDeliverables
          }
        },
        {}
      )
      break
    default:
      flightDeliverables = reduce(
        seasonIds,
        (accumulator, seasonId) => {
          const deliverables = getDeliverablesBySeasonIdAndSource(seasonId, source)(state)
          const previousDeliverables = get(accumulator, 'deliverables', [])
          const previousPdfDeliverables = get(accumulator, 'pdfDeliverables', [])

          const newDeliverables = get(deliverables, 'deliverables')
          const newPdfDeliverables =
            isSingleSelection && _buildSatellitePdfDeliverables(get(deliverables, 'pdfDeliverables'), basePdfGenerationUrl, flightDate, seasonId, language)

          const allDeliverables = unionBy(previousDeliverables, newDeliverables, 'type.id')
          const allPdfDeliverables = isSingleSelection && unionBy(previousPdfDeliverables, newPdfDeliverables, 'type.id')

          return {
            ...accumulator,
            deliverables: allDeliverables,
            pdfDeliverables: allPdfDeliverables
          }
        },
        {}
      )
      break
  }

  return {
    ...flight,
    ...flightDeliverables
  }
}

const _buildSatellitePdfDeliverables = (deliverables, basePdfGenerationUrl, flightDate, seasonId, language) => {
  return map(deliverables, deliverable => {
    const pdfUrl = new URL(basePdfGenerationUrl)
    pdfUrl.searchParams.set('target_date', moment(flightDate, 'YYYY-MM-DD').format('YYYY-MM-DD'))
    pdfUrl.searchParams.set('season_id', seasonId)
    pdfUrl.searchParams.set('language', language)
    pdfUrl.searchParams.set('deliverable_type', get(deliverable, ['type', 'base_file_name']))
    return {
      ...deliverable,
      s3_url: pdfUrl,
      type: {
        ...get(deliverable, 'type'),
        format: {
          ...get(deliverable, ['type', 'format']),
          id: RAW_DOWNLOAD,
          name: 'raw_download'
        }
      }
    }
  })
}

const _addDeliverableUiControls = deliverable => {
  const visible = get(deliverable, ['type', 'order_by']) === 1
  return {
    ...deliverable,
    opened: false,
    visible,
    opacity: 1
  }
}

const getGeometriesQuery = loader('../graphql/getGeometries.gql').loc.source.body

export const fetchGeometries = () => dispatch =>
  dispatch(
    fetchGraphqlWrapper({
      requestType: REQUEST_GEOMETRIES,
      query: getGeometriesQuery,
      onSuccess: ({ geometries }) => {
        return receiveGeometries(geometries)
      }
    })
  )

const receiveGeometries = geometries => dispatch => {
  return dispatch({ type: RECEIVE_GEOMETRIES, geometries })
}

const getGeometriesByFieldIdsQuery = loader('../graphql/getGeometriesByFieldIds.gql').loc.source.body

export const fetchGeometriesByFieldIds = fieldIds => dispatch =>
  dispatch(
    fetchGraphqlWrapper({
      requestType: REQUEST_GEOMETRIES_BY_FIELD_IDS,
      query: getGeometriesByFieldIdsQuery,
      variables: { fieldIds },
      onSuccess: ({ geometries }) => receiveGeometries(geometries)
    })
  )

/*----------- Fetch fields -----------*/

// This function adds deliverables to seasons. It is super expensive, but we need it.
// So don't delete it, but consider refactoring.

const _addDeliverablesToSeason = (season, state) => {
  const seasonTypeId = get(season, ['type', 'id'])
  const fieldId = get(season, 'fieldId')
  const customer = getFieldCustomerById(state, fieldId)
  const customerId = get(customer, 'id')
  const deliverables = getDeliverablesBySource(customerId, seasonTypeId)(state)

  return {
    ...season,
    deliverables
  }
}

export const receiveFieldIds = fieldIds => dispatch => {
  return dispatch({ type: RECEIVE_FIELDS_COUNT, fieldIds })
}

export const updateFieldIds = fieldId => dispatch => {
  return dispatch({ type: UPDATE_FIELDS_COUNT, fieldId })
}

export const receiveFields = fields => (dispatch, getState) => {
  const state = getState()
  // biggest performance bottleneck
  const seasonsWithDeliverables = reduce(
    fields,
    (acc, field) => {
      const seasons = get(field, 'seasons')
      const currentSeason = map(seasons, season => _addDeliverablesToSeason(season, state))
      return [...acc, ...currentSeason]
    },
    []
  )
  dispatch({ type: RECEIVE_FIELDS_SEASONS_BY_IDS, seasons: seasonsWithDeliverables })
  return dispatch({ type: RECEIVE_FIELDS, fields, receivedAt: Date.now() })
}

const getFieldsQuery = loader('../graphql/getFields.gql').loc.source.body

export const fetchFields = () => dispatch =>
  dispatch(
    fetchGraphqlWrapper({
      requestType: REQUEST_FIELDS,
      query: getFieldsQuery,
      onSuccess: ({ fields }) => {
        return receiveFields(fields)
      }
    })
  )

const getFieldsById = loader('../graphql/getFieldsById.gql').loc.source.body

export const fetchFieldsByIds = (fieldIds, existingFields = [], onSuccess = noop) => dispatch => {
  return dispatch(
    fetchGraphqlWrapper({
      requestType: REQUEST_FIELDS_BY_ID,
      variables: { fieldIds, existingFields },
      overlay: true,
      query: getFieldsById,
      onSuccess: ({ fields }) => {
        onSuccess()
        return receiveFields(fields)
      },
      onError: console.log
    })
  )
}

export const getNextFieldsChunk = () => (dispatch, getState) => {
  const state = getState()
  const fieldStats = getFieldStats(state)

  const currentChunk = get(fieldStats, 'currentChunk')
  const nextChunk = currentChunk + 1

  const nextChunkArray = get(fieldStats, 'chunks')[nextChunk]
  dispatch({
    type: UPDATE_CURRENT_CHUNK,
    currentChunk: nextChunk
  })

  return dispatch(
    fetchFieldsByIds(
      nextChunkArray.map(field => field.id),
      []
    )
  )
}

export const fetchFilteredFields = () => (dispatch, getState) => {
  const state = getState()
  const search = getFiltersSearchInputString(state)
  const customerId = getFiltersCustomersIds(state)
  const seasonsTypeId = getFiltersCropsIds(state)
  const cutNumber = getFiltersCutNumber(state)
  const seasonLabel = getFiltersSeasonLabel(state)
  const plantationDateRange = getFiltersPlantationDateRange(state)

  const query = buildSearchQuery({
    search,
    plantationDateRange,
    customerId: isEmpty(customerId) ? null : customerId[0],
    seasonsTypeId: isEmpty(seasonsTypeId) ? null : seasonsTypeId[0],
    cutNumber: isEmpty(cutNumber) ? null : cutNumber[0],
    seasonLabel: isEmpty(seasonLabel) ? null : seasonLabel[0]
  })

  dispatch(
    fetchGraphqlWrapper({
      requestType: REQUEST_FILTERED_FIELDS,
      query,
      onSuccess: ({ totalCount, fields }) => {
        dispatch(receiveFieldIds(totalCount))
        return dispatch(receiveFields(fields))
      }
    })
  )
}

const getFieldsWithLimit = loader('../graphql/getFieldsWithLimit.gql').loc.source.body

export const fetchFieldsWithLimit = limit => (dispatch, getState) => {
  const state = getState()
  const existingFields = getFieldsIds(state)
  dispatch(
    fetchGraphqlWrapper({
      requestType: REQUEST_FIELDS_BY_ID,
      variables: { limit: limit || 25, existingFields },
      query: getFieldsWithLimit,
      onSuccess: ({ fields }) => {
        return receiveFields(fields)
      },
      onError: console.log
    })
  )
}

const getFieldIds = loader('../graphql/statistics/getFieldIds.gql').loc.source.body

export const fetchFieldIds = () => dispatch => {
  dispatch(
    fetchGraphqlWrapper({
      requestType: REQUEST_FIELDS_BY_ID,
      query: getFieldIds,
      onSuccess: ({ fieldIds }) => {
        return receiveFieldIds(fieldIds)
      },
      onError: console.log
    })
  )
}

/*----------- Fetch field types -----------*/

const receiveFieldTypes = types => ({
  type: RECEIVE_FIELD_TYPES,
  types,
  receivedAt: Date.now()
})

const getFieldTypes = loader('../graphql/getFieldTypes.gql').loc.source.body
export const fetchFieldTypes = () =>
  fetchGraphqlWrapper({
    requestType: REQUEST_FIELD_TYPES,
    query: getFieldTypes,
    onSuccess: ({ types }) => receiveFieldTypes(types)
  })
/*----------- Fetch filter values -----------*/

const receiveFieldTypesValues = fieldTypes => ({
  type: RECEIVE_FIELD_TYPES_VALUES,
  fieldTypes,
  receivedAt: Date.now()
})

const getFieldTypesValuesQuery = loader('../graphql/getFieldTypesValues.gql').loc.source.body
export const fetchFieldTypesValues = () =>
  fetchGraphqlWrapper({
    requestType: REQUEST_FIELD_TYPES_VALUES,
    query: getFieldTypesValuesQuery,
    onSuccess: ({ fieldTypes }) => receiveFieldTypesValues(fieldTypes)
  })

const receiveCutNumberValues = cutNumberValues => ({
  type: RECEIVE_CUT_NUMBER_VALUES,
  cutNumberValues,
  receivedAt: Date.now()
})

const getCutNumberValuesQuery = loader('../graphql/getCutNumberValues.gql').loc.source.body
export const fetchCutNumberValues = () =>
  fetchGraphqlWrapper({
    requestType: REQUEST_CUT_NUMBER_VALUES,
    query: getCutNumberValuesQuery,
    onSuccess: ({ cutNumberValues }) => receiveCutNumberValues(cutNumberValues)
  })

const receiveSeasonLabelValues = seasonLabelValues => ({
  type: RECEIVE_SEASON_LABEL_VALUES,
  seasonLabelValues,
  receivedAt: Date.now()
})

const getSeasonLabelValuesQuery = loader('../graphql/getSeasonLabelValues.gql').loc.source.body
export const fetchSeasonLabelValues = () =>
  fetchGraphqlWrapper({
    requestType: REQUEST_SEASON_LABEL_VALUES,
    query: getSeasonLabelValuesQuery,
    onSuccess: ({ seasonLabelValues }) => receiveSeasonLabelValues(seasonLabelValues)
  })

const receiveCustomerValues = customerValues => ({
  type: RECEIVE_CUSTOMER_VALUES,
  customerValues,
  receivedAt: Date.now()
})

const getCustomerValuesQuery = loader('../graphql/getCustomerValues.gql').loc.source.body
export const fetchCustomerValues = () =>
  fetchGraphqlWrapper({
    requestType: REQUEST_CUSTOMER_VALUES,
    query: getCustomerValuesQuery,
    onSuccess: ({ customerValues }) => receiveCustomerValues(customerValues)
  })
/*----------- Fetch irrigation types -----------*/

const receiveIrrigationTypes = irrigationTypes => ({
  type: RECEIVE_IRRIGATION_TYPES,
  payload: irrigationTypes
})

const getIrrigationTypes = loader('../graphql/getIrrigationTypes.gql').loc.source.body

export const fetchIrrigationTypes = () =>
  fetchGraphqlWrapper({
    requestType: REQUEST_IRRIGATION_TYPES,
    query: getIrrigationTypes,
    onSuccess: ({ types }) => receiveIrrigationTypes(types)
  })

/*** SELECT FIELDS -- FIELDS GROUP **/

export const removeField = fieldId => ({
  type: REMOVE_FIELD_TO_GROUP,
  fieldId
})

export const removeFieldToGroup = fieldId => (dispatch, getState) => {
  dispatch(removeField(fieldId))
  const state = getState()
  if (getScreenView(state) !== SEARCH_VIEW) {
    getSelectedFieldsLength(state) <= 1 && dispatch(openSearchMode())
  }
}

export const addFieldsToGroup = fieldsIds => (dispatch, getState) => {
  const state = getState()
  const existingFields = getFieldsIds(state)
  const unfetchedFields = difference(fieldsIds, existingFields)

  dispatch({
    type: ADD_FIELDS_TO_GROUP,
    fieldsIds
  })
  if (!isEmpty(unfetchedFields)) {
    dispatch(fetchFieldsByIds(unfetchedFields))
  }
}

export const setPreviewFieldId = fieldId => ({
  type: SET_PREVIEW_FIELD_ID,
  fieldId
})

export const resetPreviewFieldId = () => ({
  type: RESET_PREVIEW_FIELD_ID
})

export const flushFieldsGroup = () => ({
  type: FLUSH_FIELDS_GROUP
})

/**
 * this function is triggerred after clicking on the BIG layer button on the bottom center
 * it will enhance our selected fields by adding satellite snapshots to it and UI features
 * Then with our enhanced fields, we will create flight groups to handle UI
 *
 * @param {*} from
 * @param {*} to
 * @param {*} hasLimit
 *
 */
export const selectFields = (from = moment(moment().format('YYYY-MM-01')).subtract({ month: 2 }), to = moment(), hasLimit) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    // get all needed season ids based on from - to
    const state = getState()
    const isSHCalendar = hasUserRole(state, ROLE_SH_CALENDAR)
    const selectedFieldsIds = getSelectedFieldsIds(state)

    dispatch(fetchFieldsByIds(selectedFieldsIds))
      .then(() => {
        // fetch all needed data to build flight groups
        // fetch seasons
        return dispatch(fetchSelectedFieldsSeasonsByDateRange(from, to))
      })
      .then(() => {
        // fetch snapshots
        const selectedFieldsIds = getSelectedFieldsIds(state)
        if (!isSHCalendar) {
          return dispatch(fetchDBFlights(selectedFieldsIds, from, to))
        }
        return dispatch(fetchSelectedFieldsFlights(from, to))
      })
      .then(() => {
        // create flight groups with fetched infos
        const state = getState()
        const snapshots = getSnapshots(state)
        const flightDifferenceDays = getConfigData(state, 'flightDifferenceDays')
        const flightGroups = createFlightGroups(snapshots, flightDifferenceDays)(getState)

        if (isEmpty(flightGroups) && !hasLimit) {
          const previousFrom = from.subtract({ month: 1 })
          const startDate = moment(from)
          const previousTo = startDate.endOf('month')
          dispatch(selectFields(previousFrom, previousTo, true))
          dispatch(setActiveCalendarDate(previousFrom))

          // this prevents the slight no-info preview when no flights are found
          return
        }

        dispatch(addFlightGroups(flightGroups))
        // fetch samples and set allSamples mode
        dispatch(fetchActiveFieldsSamples())
        resolve()
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const selectFieldsAction = fields => ({
  type: SELECT_FIELDS,
  payload: fields
})

export const addFlightGroups = flightGroups => ({
  type: ADD_FLIGHT_GROUPS,
  payload: flightGroups
})

/**
 * create flightGroups by a selection of fields using a period parameter
 * the map will be done taking in consideration the type of flights ( satellite or drone )
 * and map into this structure
 *    flights :group of dates,    template of del ( UI) ,  template of pdfdel ( UI), tab selected, snapshot selected
 *  [ { [d1,d2,d2]          ,    deliverables          ,   pdfdeliverab           , activeLayers, selcted           } , {....} ]
 *  in the flightBarSlider, we always show d1
 *  in layers, we use the template of deliverables just to retrieve the good snapshots deliverables in selectedFields
 *  @param {*} snapshots
 * @param {*} flightDifferenceDays
 */
export const createFlightGroups = (snapshots, flightDifferenceDays) => getState => {
  // flatten normalized flights
  const flights = []
  forIn(snapshots, sources => forIn(sources, flight => flights.push(flight)))
  const sortedFlights = sortBy(flights, flight => new Date(flight.date))

  // /* we create flights here ( we group flight dates ) with snapshot period */
  return (
    sortedFlights
      .reduce((accumulator, flight) => {
        if (accumulator.length === 0) {
          accumulator = [[flight]]
        } else {
          /* we search for the index of the first occurence that are the same type ( satellite or drone) */
          // eslint-disable-next-line prefer-const
          let reverseIndex = accumulator
            .map(group => group[0])
            .reverse()
            .findIndex(element => element.source === flight.source)
          /* if it s not fnd means you are just in the satellite & drones visualization
          we just add it to our array */
          if (reverseIndex === -1) {
            accumulator = [...accumulator, [flight]]
          } else {
            /* as the index is reversed we will use accumulator.length */
            // eslint-disable-next-line prefer-const
            let currentFlightIndex = accumulator.length - 1 - reverseIndex

            // eslint-disable-next-line prefer-const
            let currentFlight = accumulator[currentFlightIndex][0]

            if (Math.abs(moment(currentFlight.date).diff(flight.date, 'days')) <= flightDifferenceDays[currentFlight.source]) {
              accumulator[currentFlightIndex] = [...accumulator[currentFlightIndex], flight]
            } else accumulator = [...accumulator, [flight]]
          }
        }

        return accumulator
      }, [])
      //     /* we map then the flights with their common deliverables and UI selected snapshot */
      .map(flightGroupFlights => createFlightGroup(flightGroupFlights)(getState))
  )
  // return []
}

/**
 * This function create a flightGroup which handle the UI of a specific flight/snapshot
 * It comes bundled with deliverables (layers/pdf ) used for UI bottom left box
 * it comes with a group of flights ( the first one is mainly used )
 * it's a group of flights because we group them by date range when mapping it
 * @param {*} flights
 * @param {*} selected  the visible flight
 */
export const createFlightGroup = flights => getState => {
  const state = getState()
  const flightsWithDeliverables = map(flights, flight => _buildFlightDeliverables(flight, state))
  const flightGroupDeliverables = reduce(
    flightsWithDeliverables,
    (accumulator, flight) => {
      const previousDeliverables = get(accumulator, 'deliverables', [])
      const previousPdfDeliverables = get(accumulator, 'pdfDeliverables', [])

      const flightDeliverables = map(get(flight, 'deliverables'), _addDeliverableUiControls)
      const flightPdfDeliverables = get(flight, 'pdfDeliverables')

      const allFlightDeliverables = unionBy(previousDeliverables, flightDeliverables, 'type.id')
      const allFlightPdfDeliverables = unionBy(previousPdfDeliverables, flightPdfDeliverables, 'type.id')

      const sortedFlightDeliverables = orderBy(allFlightDeliverables, ['type.order_by'], ['desc'])
      const sortedFlightPdfDeliverables = orderBy(allFlightPdfDeliverables, ['type.order_by'], ['desc'])

      return {
        deliverables: sortedFlightDeliverables,
        pdfDeliverables: sortedFlightPdfDeliverables
      }
    },
    {}
  )

  return {
    selected: false,
    ...flightGroupDeliverables,
    flights: flightsWithDeliverables,
    activeLayers: true
  }
}

export const deselectFieldsAction = () => ({
  type: DESELECT_FIELDS
})

export const deselectFields = () => dispatch => {
  dispatch(deselectFieldsAction())
  return dispatch(openSearchMode())
}

export const selectFieldAction = field => ({
  type: SELECT_FIELD,
  // eslint-disable-next-line object-shorthand
  payload: { field: field }
})

export const selectSnapshot = id => ({
  type: SELECT_SNAPSHOT,
  payload: { snapshotId: id }
})

export const selectFlightGroup = id => (dispatch, getState) => {
  dispatch({ type: SELECT_FLIGHTGROUP, payload: { snapshotId: id } })
  const state = getState()
  const fieldSeasonIds = getSelectedFlightGroupSeasonIds(state)
  const tempComparisonDate = getTempComparisonDate(state)

  // reset if there is no common sources for current and tempComparison dates
  if (tempComparisonDate) {
    const commonSourcesWithTemporalComparisonDate = isCommonSourcesWithTemporalComparisonDate(state)
    if (!commonSourcesWithTemporalComparisonDate) {
      dispatch(resetTempComparison())
      dispatch(errorNotification('You can only compare dates from the same sources'))
    }
  }

  return dispatch(fetchSeasonLayersBySeasonIds(fieldSeasonIds))
}

export const moveTemporalSlider = value => ({
  type: MOVE_TEMPORAL_SLIDER,
  payload: value
})

export const selectFieldImage = image => ({
  type: SELECT_FIELD_IMAGE,
  payload: image
})

export const deselectFieldImage = () => ({
  type: DESELECT_FIELD_IMAGE
})

/**
 * this function is used to make temporal comparison
 * @param {} flightGroup
 */
export const compareToFlightGroup = date => (dispatch, getState) => {
  const state = getState()
  const currentVisibleDeliverable = getSelectedFlightGroupFirstVisibleDeliverable(state)
  dispatch(clearVisibilities())
  dispatch(toggleVisibility(currentVisibleDeliverable))
  return dispatch({ type: SET_TEMPORAL_COMPARISON_DATE, date })
}

export const downloadSelectedFieldsGeoJson = () => (dispatch, getState) => {
  dispatch(setRequestStart(REQUEST_DOWNLOAD_FIELDS_GEOJSON))
  const state = getState()
  const userName = getUserName(state)
  // eslint-disable-next-line prefer-const
  let fields = getSelectedFields(state)
  const geometries = getGeometriesByFields(fields)(state)

  const fieldsFilteredByGeometry = reduce(
    fields,
    (fieldsWithGeometry, field) => {
      const fieldWithGeometry = find(geometries, geom => geom.id === field.id)
      const geometry = get(fieldWithGeometry, ['geometry'])
      if (geometry) {
        return [...fieldsWithGeometry, { ...field, geometry }]
      }

      return fieldsWithGeometry
    },
    []
  )

  if (isEmpty(fieldsFilteredByGeometry)) {
    dispatch(errorNotification('Fields without geometries'))
    return dispatch(setRequestFail(REQUEST_DOWNLOAD_FIELDS_GEOJSON))
  }

  const features = map(fieldsFilteredByGeometry, field => {
    const getFromField = key => get(field, key)

    return {
      id: getFromField('id'),
      externalReferenceId: getFromField('externalReferenceId'),
      type: 'Feature',
      properties: {
        id: getFromField('id'),
        name: getFromField('name'),
        externalReferenceId: getFromField('externalReferenceId'),
        comment: getFromField('comment'),
        customer: {
          id: getFromField('customer.id'),
          name: getFromField('customer.name'),
          agrouser: {
            username: getFromField('customer.user.username'),
            id: getFromField('customer.user.id'),
            type: getFromField('customer.user.type')
          }
        },
        season: {
          startDate: getFromField('season.startDate'),
          endDate: getFromField('season.endDate'),
          seasonParameters: getFromField('season.parameters'),
          type: {
            id: getFromField('season.type.id'),
            name: getFromField('season.type.name')
          }
        }
      },
      geometry: getFromField('geometry')
    }
  })

  const filteredGeoJson = {
    type: 'FeatureCollection',
    totalFeatures: get(features, 'length'),
    features
  }

  const geoJsonStringifield = JSON.stringify(filteredGeoJson, null, 2)
  const blob = new Blob([geoJsonStringifield], { type: 'application/json' })
  const timeNow = moment().format('DD_MM_YYYY_hh_mm_ss')
  saveAs(blob, `${userName}_${timeNow}.geojson`)
  dispatch(setRequestEnd(REQUEST_DOWNLOAD_FIELDS_GEOJSON))
  dispatch({
    type: DOWNLOAD_FIELDS_GEOJSON_SUCCESS,
    filteredGeoJson,
    geoJsonStringifield
  })
}

export const goToField = (fieldsIds, isDashboard) => dispatch => {
  dispatch(flushFieldsGroup())

  if (isDashboard) {
    setTimeout(() => dispatch(addFieldsToGroup(fieldsIds)), 500)
  } else {
    dispatch(addFieldsToGroup(fieldsIds))
    dispatch(selectFields())
  }
}

export const postFieldWithSeasons = field => {
  const fieldToCreate = formSeasonsFieldToRequest(field)

  return fetchRequestWrapper({
    requestType: REQUEST_POST_FIELD_WITH_SEASONS,
    route: 'post_field_with_seasons',
    onSuccess: updateFieldAndSeasons,
    fetchOptions: {
      method: 'POST',
      headers: APPLICATION_JSON_HEADER,
      body: JSON.stringify(fieldToCreate)
    }
  })
}

export const patchFieldWithSeason = field => dispatch => {
  const fieldToPatch = formSeasonsFieldToRequest(field)

  return dispatch(
    fetchRequestWrapper({
      requestType: REQUEST_PATCH_FIELD_WITH_SEASONS,
      route: 'patch_field_with_seasons',
      urlParams: { field: fieldToPatch?.id },
      onSuccess: updateFieldAndSeasons,
      onError: error => {
        const message = values(error.errors?.[0])?.[0]
        if (message && includes(message, 'geometry')) {
          dispatch(errorNotification(message))
        } else {
          dispatch(errorNotification('An error occured from the server. Please try again'))
        }
      },
      fetchOptions: {
        method: 'PATCH',
        headers: APPLICATION_JSON_HEADER,
        body: JSON.stringify(fieldToPatch)
      }
    })
  )
}

export const onDeleteField = fieldId => dispatch => {
  dispatch(
    fetchRequestWrapper({
      requestType: REQUEST_DELETE_FIELD,
      route: 'delete_field',
      urlParams: { field: fieldId },
      fetchOptions: {
        method: 'DELETE',
        headers: APPLICATION_JSON_HEADER
      },
      onSuccess: json => dispatch => dispatch(updateFieldAfterDeleting(fieldId)),
      onError: error => {
        console.log(error)
        return errorNotification('An error occured from the server. Please try again')
      }
    })
  )
}

export const deleteFieldFromList = fieldId => ({
  type: DELETE_FIELD_FROM_LIST,
  fieldId
})

// searchable graphql query for fields
// searchParameters structure:
// {
//   seasonsTypeId: number|null,
//   cutNumber: number|null,
//   seasonLabel: string|null,
//   customerId: string|null
// }
const buildWhereClause = (searchParameters = {}) => {
  const seasonWhere = {}
  let customerWhere = '{}'
  const seasonsTypeId = get(searchParameters, 'seasonsTypeId', null)
  const cutNumber = get(searchParameters, 'cutNumber', null)
  const seasonLabel = get(searchParameters, 'seasonLabel', null)
  const customerId = get(searchParameters, 'customerId', null)
  const plantationDateRange = get(searchParameters, 'plantationDateRange', null)
  const from = get(plantationDateRange, 'from', null)
  const to = get(plantationDateRange, 'to', null)

  let agroFieldSeasonString = `{`

  let addDate = false

  if (seasonsTypeId) {
    agroFieldSeasonString += `agro__field_type: { id: { _eq: ${seasonsTypeId} } }`
    addDate = true
  }

  if (from && to) {
    if (seasonsTypeId) agroFieldSeasonString += `, `
    agroFieldSeasonString += `agro__field_season_parameter_values: {
      agro__field_season_parameter: {
        name: { _eq: "plantation_date" }
      },
      value: { _gte: "${from}", _lte: "${to}" }
    }`
    addDate = true
  }

  if (cutNumber) {
    if (seasonsTypeId || (from && to)) agroFieldSeasonString += `, `
    agroFieldSeasonString += `agro__field_season_parameter_values: {
      agro__field_season_parameter: {
        name: { _eq: "cut_number" }
      },
      value: { _eq: "${cutNumber}" }
    }`
    addDate = true
  }

  if (seasonLabel) {
    if (seasonsTypeId || cutNumber || (from && to)) agroFieldSeasonString += `, `
    agroFieldSeasonString += `label: { _eq: "${seasonLabel}" }`
    addDate = true
  }

  if (customerId) {
    customerWhere = `{id: {_eq: ${customerId}} }`
  }

  if (addDate) {
    agroFieldSeasonString += `, start_date: { _lte: "now()" }, end_date: { _gte: "now()" }`
    seasonWhere.start_date = { _lte: 'now()' }
    seasonWhere.end_date = { _gte: 'now()' }
  }

  agroFieldSeasonString += `}`

  return {
    agroFieldSeasonString,
    customerWhere
  }
}

const buildSearchQuery = searchParameters => {
  const search = get(searchParameters, 'search', '""')
  const whereClause = buildWhereClause(searchParameters)
  const agroFieldSeasonString = get(whereClause, 'agroFieldSeasonString', {})
  const customerWhere = get(whereClause, 'customerWhere', {})

  const query = `
    query getFields {
      totalCount: agro__field(
        where: {
          _or: [
            {name: {_ilike: "%${search}%"}}, 
            {agro__customer: {name: {_ilike: "%${search}%"}}}, 
            {external_reference_id: {_ilike: "%${search}%"}}, 
            {agro__field_seasons: {label: {_ilike: "%${search}%"}}}, 
            {agro__field_seasons: {agro__field_type: {name: {_ilike: "%${search}%"}}}}, 
            {agro__field_seasons: {agro__field_sub_type: {name: {_ilike: "%${search}%"}}}}, 
            {comment: {_ilike: "%${search}%"}}],
          ${agroFieldSeasonString === '{}' ? '' : `agro__field_seasons: ${agroFieldSeasonString},`}
          ${customerWhere === '{}' ? '' : `agro__customer: ${customerWhere}`}
        }
      ){
        id
      }
      fields: agro__field (
        where: {
          _or: [
            {name: {_ilike: "%${search}%"}}, 
            {agro__customer: {name: {_ilike: "%${search}%"}}}, 
            {external_reference_id: {_ilike: "%${search}%"}}, 
            {agro__field_seasons: {label: {_ilike: "%${search}%"}}}, 
            {agro__field_seasons: {agro__field_type: {name: {_ilike: "%${search}%"}}}}, 
            {agro__field_seasons: {agro__field_sub_type: {name: {_ilike: "%${search}%"}}}}, 
            {comment: {_ilike: "%${search}%"}}],
          ${agroFieldSeasonString === '{}' ? '' : `agro__field_seasons: ${agroFieldSeasonString},`}
          ${customerWhere === '{}' ? '' : `agro__customer: ${customerWhere}`}
        },
        limit: ${CHUNK_SIZE}
      ){
        id
        name
        comment
        externalReferenceId: external_reference_id
        customer: agro__customer {
          id
          name
        }
        seasons: agro__field_seasons(order_by: { start_date: desc }) {
          id
          startDate: start_date
          endDate: end_date
          type: agro__field_type {
            id
            name
          }
    
          subtype: agro__field_sub_type {
            id
            name
          }
          irrigationType: irrigation_type {
            id
            name
          }
          fieldId: field_id
          surface
          geometry
          parameters: agro__field_season_parameter_values {
            parameter: agro__field_season_parameter {
              id
              name
            }
            value
          }
          label
        }
      }
    }  
  `

  return query
}
