// prettier-ignore
export default {
  "-0.10 Very Low": "-0.10 Muito baixo",
  "% Mapa alertas": "% Mapa alertas",
  "% Media Fosforo": "% Media Fosforo",
  "% Media Nitrogeno": "% Media N",
  "% Media Potasio": "% Media Potássio",
  "% resiembra": "% replantio",
  "0.10 Low": "0.10 Baixo",
  "0.20 Very Low": "0.20 Muito baixo",
  "0.25 Very Low": "0.25 Muito baixo",
  "0.30 Low": "0.30 Baixo",
  "0.30 Medium": "0.30 Médio",
  "0.40  Low": "0.40  Baixo",
  "0.40 Medium": "0.40 Médio",
  "0.45 High": "0.45 Alto",
  "0.50 High": "0.50 Alto",
  "0.55 Medium": "0.55 Médio",
  "0.65 Very High": "0.65 Muito alto",
  "0.70 High": "0.70 Alto",
  "0.85 Very High": "0.85 Muito alto",
  "abonado de cobertera": "Adubação de cobertura",
  "abonado de fondo": "Fertilização de base",
  "abonado de fondo ": "Fertilização de base",
  "abonado dosificado": "Fertilização por Dose",
  "abonado tasa fija": "Adubação Tasa Fixa",
  "abonado zonificado": "Fertilização por Zona",
  "accumulated vegetative growth": "Crescimento Vegetativo Acumulado",
  "Acre": "Acre",
  "active": "ativas",
  "Add at least one recipient (+)": "Adicione pelo menos um destinatário (+)",
  "Add Field Button": "Botão Adicionar Campo",
  "Add Field Tutorial": "Tutorial: Adicionar talhões",
  "add location": "adicionar localização",
  "Add new sample": "Adicionar amostra nova",
  "add photo": "adicionar foto",
  "added to pending": "adicionado a pendente",
  "Advanced": "Avançado",
  "advanced season parameters": "parâmetros da safra avançados",
  "afección": "Condição",
  "afección extensivo": "Condição Extensiva",
  "Affected Surface": "Superfície Afetada",
  "After filling the form, you can draw the field": "Depois de preencher o formulário, você pode desenhar o talhão",
  "agrodelivery": "Entrega Agronômicas",
  "all": "todas",
  "ALMENDRA": "AMÊNDOA",
  "alto estrés hídrico": "Alto estresse hidrico",
  "alto pot prod": "Alto Pot Prod",
  "ambiente": "Ambiente",
  "An error occured": "Ocorreu um erro",
  "An error occurred from the server. Please try again": "Ocorreu um erro no servidor. Tente novamente",
  "And that's it!": "E isso é tudo!!",
  "anexo información": "Informação em Anexo",
  "Applied filters": "Filtros aplicados",
  "Apply": "Feito",
  "apply date filter": "Aplicar filtro de data",
  "Apply selection": "Aplicar seleção",
  "Are you sure to close the editor?": "Tem certeza de fechar o editor?",
  "Are You sure?": "Tem certeza?",
  "area": "superfície",
  "Area": "Área",
  "ATR": "Açúcar Total Recuperável",
  "author": "Autor",
  "Automatic harvest": "Colheita automática",
  "auxiliary files": "Arquivos auxiliares",
  "back": "retorna",
  "bajo estrés hídrico": "Baixo estresse hidrico",
  "bajo pot prod": "Baixo Pot Prod",
  "Base temperature": "Temperatura base",
  "baseLayer": "Camada de base",
  "Basic": "Básico",
  "basic season parameters": "parâmetros básicos da safra",
  "bipolaris_sacchari_g1": "bipolaris sacchari \"mancha del ojo\" - g1",
  "bipolaris_sacchari_g2": "bipolaris sacchari \"mancha del ojo\" - g2",
  "bipolaris_sacchari_g3": "bipolaris sacchari \"mancha del ojo\" - g3",
  "bipolaris_sacchari_g4": "bipolaris sacchari \"mancha del ojo\" - g4",
  "bipolaris_sacchari_g5": "bipolaris sacchari \"mancha del ojo\" - g5",
  "Both first and last points of the slice line must be outside of the polygon/circle": "Os pontos inicial e final da linha de corte devem estar fora do polígono/círculo",
  "Buried": "Buried",
  "calibre": "Calibre",
  "camera error message": "Parece que há um problema de permissões.",
  "camera_unavailable": "câmera não disponível",
  "Can't modify field with active planet subscription": "Não é possível modificar um talhao com uma subscrição do Planet ativa",
  "cancel": "Cancela",
  "cane_num": "Número de juncos",
  "cantidad": "Quantidade",
  "capa base": "Camada de base",
  "Capture screenshot": "Captura de tela",
  "Change opacity": "Alterar opacidade",
  "Choose at least one field from the main search list": "Escolha um talhõe da lista principal de pesquisa",
  "Choose your layer": "Escolha sua camada",
  "Choose your tool": "Escolha sua ferramenta",
  "Circle Drawing": "Desenhar Círculos",
  "class": "Classe",
  "Class values must be numerical": "Os valores das classes devem ser numéricos",
  "classes": "Classes",
  "classes number": "Número de classes",
  "clear": "Limpar",
  "Clear all": "Restaurar tudo",
  "clear form": "redefinir formulário",
  "clear selected fields": "Limpar campos selecionados",
  "Clicking this button, you can toggle the visibility of the selected layer.": "Clicando neste botão, você pode alternar a visibilidade da camada selecionada.",
  "clientDeviation": "Desvio do Cliente",
  "Close": "Fechar",
  "Close editor": "Fechar editor",
  "Close selection": "Fechar seleção",
  "Close the temporal Comparison": "Fechar a comparação temporal",
  "close_selected": "Fechar seleção",
  "closed": "fechado",
  "cloud": "Nuvem",
  "Cloud Coverage": "Nebulosidade",
  "comment": "comente",
  "comp. temporal target": "Comp. Alvo Temporário",
  "comp. temporal variabilidad": "Comp. Variabiliadade Temporária",
  "comparación de conteo": "Comparação de contagem (%)",
  "comparación de estado hídrico": "Comparação de estado hídrico (%)",
  "comparación de estado hídrico drone": "Comparação do estado hidrico - Drone (%)",
  "comparación de estado hídrico sat": "Comparação do estado hidrico - SAT (%)",
  "comparación de estimación de producción": "Comparação de estimativa de produção médio (%)",
  "comparación de fósforo": "Comparação de fósforo (%)",
  "comparación de mapa de alertas": "Comparação de mapa de alerta (%)",
  "comparación de mapa de faltas": "Comparação de mapa de falhas (%)",
  "comparación de media de conteo": "Comparação de médias de Contagem (%)",
  "comparación de media de estado hídrico": "Comparação de médias de estado hídrico (%)",
  "comparación de media de fósforo": "Comparação de médias de fósforo (%)",
  "comparación de media de mapa de alertas": "Comparação de médias de mapa de alerta (%)",
  "comparación de media de mapa de faltas": "Comparação de médias de mapa de falhas (%)",
  "comparación de media de nitrógeno": "Comparação de médias de nitrogênio (%)",
  "comparación de media de potasio": "Comparação de médias de potássio (%)",
  "comparación de media de producción total": "Comparação de médias de produção total (%)",
  "comparación de media de rendimiento graso": "Comparação de médias de teor de Gordura (%)",
  "comparación de medias de estimación de producción": "Comparação de médias de estimativa de produção (%)",
  "comparación de medias de ndvi": "Comparação de médias de NDVI (%)",
  "comparación de medias de proteína": "Comparação de médias de proteína (%)",
  "comparación de medias de variabilidad": "Comparação de médias de variabilidade (%)",
  "comparación de ndvi": "Comparação de NDVI (%)",
  "comparación de nitrógeno": "Comparação de nitrogênio (%)",
  "comparación de nitrógeno relativo": "Comparação de nitrogênio relativo (%)",
  "comparación de potasio": "Comparação de potássio (%)",
  "comparación de producción total": "Comparação de produção total (%)",
  "comparación de proteína": "Comparação de proteína (%)",
  "comparación de rendimiento graso": "Comparação de teor de gordura (%)",
  "comparación de sacarosa": "Comparação da sacarose",
  "comparación de variabilidad": "Comparação de variabilidade (%)",
  "confirm": "Confirme",
  "Congratulations! Your field has been created.": "Parabéns! Seu talhão foi criado.",
  "Congratulations! Your field has been edited.": "Parabéns! Seu talhão foi editado.",
  "Contact": "Contato",
  "Contenido de sacarosa ( % )": "Conteúdo de sacarose ( % )",
  "conteo - pdf rgb": "contagem - pdf rgb",
  "conteo - shps": "contagem - shps",
  "conteo de individuos": "Contegem de Indivíduos",
  "conteo lechugas": "Contagem de Alfaces",
  "continue": "prosseguir",
  "CORN": "MILHO",
  "correction": "Correção",
  "Corte nitrogeno": "Corte nitrogeno",
  "cost": "Custo",
  "count (units)": "Contagem",
  "Counting": "Contando",
  "create": "criar",
  "Create New Field": "Criar novo talhão",
  "CREATE_RANGES": "Com base no histograma da imagem, crie faixas com uma porcentagem de dados semelhantes em cada faixa.",
  "created": "Criado",
  "crecimiento cultivo": "Crescimento do cultivo",
  "Crop": "Cortar",
  "Crop type": "Tipo de cultivo",
  "CROP_INSPECTION": "Inspeção de talhões",
  "cultive": "Cultivo",
  "Cultivo": "Cultivo",
  "current season": "temporada actual",
  "current selection could take a moment to process": "O período atual foi aumentado, a solicitação pode demorar um pouco para ser processada",
  "Customer": "Cliente",
  "customerProductionEstimation": "Estimativa de produção do cliente",
  "customers": "Cliente",
  "cut": "Corte",
  "Cutter": "Aparar",
  "dashboard": "Painel",
  "date": "Data",
  "DD/MM/YYYY": "DD/MM/YYYY",
  "delete": "excluir",
  "Delete": "Apagar",
  "Delete downloaded report": "Excluir relatório baixado",
  "delete downloaded sample": "eliminar amostra baixada",
  "Delete field": "Apagar talhão",
  "delete layers?": "eliminar camadas?",
  "delete sample": "eliminar amostra",
  "delete sample edition": "eliminar edição de amostra",
  "DeleteField": "O talhão foi apagado",
  "Deliverables tutorial": "Tutorial: transmissões",
  "Description": "Descrição",
  "desde": "De",
  "Deselect": "Desselecionar",
  "deselect all": "Desmarcar todos",
  "deviation": "Desvio",
  "Did you forget your password ?": "Esqueceu sua senha ?",
  "disponible en visor": "Disponível no visor",
  "do you want to delete": "você quer apagar",
  "documentation": "Documentação",
  "don't show again": "não mostre de novo",
  "Done": "Feito",
  "dosificación genérica": "Dosagem genérica",
  "Dosis Media Cliente": "Meia dose do cliente",
  "Dosis media Propuesta": "Meia dose proposta,",
  "dosis riego": "Dose Irrigação",
  "Download": "Baixa",
  "download failed": "Os seguintes downloads falharam",
  "Download list": "Baixar lista",
  "Download selected fields samples": "Baixa de amostragem de talhões selecionadas",
  "download selected geometries": "Baixar geometrias selecionadas",
  "download values": "Valores de download",
  "downloaded": "baixado",
  "downloading layers": "baixando camadas",
  "downloads": "Downloads",
  "Drag and drop your layers to arrange them in the list.": "Arraste e solte suas camadas para organizá-las na lista",
  "Drag Fields": "Arrastrar talhões",
  "Drag your layers": "Arrastrar suas camadas",
  "Draw Field": "Desenhar talhão",
  "dron": "Drone",
  "Dron sectores": "Dron sectores",
  "drop here": "arrastra aquí",
  "Dry": "Seco",
  "dry matter": "Matéria seca",
  "dryMatterField": "Material seco",
  "dryMatterLaboratory": "Matéria seca de laboratório",
  "dsm": "DSM",
  "earliest harvest date": "Data colheita prévia",
  "edit": "editar",
  "Edit Field": "Editar talhão",
  "Edit geometry": "Editar geometria",
  "edit range": "Mudar rango",
  "Edit values": "Editar valores",
  "ej 100": "Ex 100",
  "End Date": "Data final",
  "Equal Interval": "Intervalo Igual",
  "Equidistant ranges": "Intervalos equidistantes",
  "error fetching classes": "O céu está muito nublado na data selecionada. Ajuste o filtro de nuvens do calendário para encontrar um dia mais limpo.",
  "estado hídrico": "Estado hídrico",
  "estado hídrico (ndmi)": "Estado Hidrico (NDMI)",
  "estado hidrico sat": "NDRE",
  "estimación": "Estimativa",
  "estimación producción": "Estimativa de produção",
  "Estimacion produccion ( Tn / Ha )": "Estimativa de produção ( Tn / Ha )",
  "estimación producción cualitativa": "Estimativa Qualitativa de Produção",
  "estimación proteína": "Estimativa de Proteína",
  "Estimacion proteina ( % )": "Estimativa proteína ( % )",
  "estimación sacarosa": "Estimativa da sacarose",
  "estimacion sacarosa ": "Estimativa da sacarose",
  "estimación sacarosa cualitativa": "Estimativa Qualitativa de Sacarose",
  "Estimacion Total ( Kg )": "Estimativa Total ( Kg )",
  "estimation gauging": "Estimativa de Medição",
  "estrategia de dosificacion": "Estratégia de dosagem",
  "estrés hidrico": "Estresee hidrico",
  "estrés hídrico (lws)": "Stress hídrico (LWS)",
  "evolución de NDVI": "Evolução de NDVI",
  "evolution": "evolução",
  "Ex : comment": "Ex : comentário",
  "Ex : field R02": "Ej : talhão R02",
  "Ex : Tresbolillos, row, tree": "Ex : Tresbolillos, linha, árvore",
  "expected_final_weight": "Peso final esperado",
  "external_id": "ID externo",
  "extra parameters": "parâmetros adicionais",
  "falhas carreadores": "Falhas Carreadores",
  "falhas internas": "Falhas Internas",
  "falhas terraços": "Falhas Terraços",
  "fat performance": "Teor de gordura",
  "faults map": "Mapa de falhas",
  "fdn": "Fdn",
  "Fecha de vuelo": "Data de voo",
  "Fecha Plantacion": "Data de plantio",
  "Feddan": "Feddan",
  "fertilization strategy": "Estratégia de fertilização",
  "Fertilizer recommendation": "Recomendação de adubação",
  "field": "talhão",
  "Field Name": "Nome do talhão",
  "Field without geometry": "Talhõe sem geometria",
  "FIELD_FILTER": "Filtros de campo",
  "field_name": "Nome de talhão",
  "FIELD_TEMPORAL_EVOLUTION": "Evolução temporal",
  "fields": "Talhões",
  "Fields without geometries": "Talhões sem geometrias",
  "File cannot be generated due to high amounts of clouds. Please select another date": "O arquivo não pode ser gerado devido à grande quantidade de nuvens. Por favor, selecione outra data",
  "File generator": "Gerador de ficheiros",
  "File name": "Nome de ficheiro",
  "file type": "Tipo de arquivo",
  "file-is-not-available-in-selected-language": "Este arquivo não está disponível no idioma selecionado. Gerado em inglês.",
  "Fill plantation date of current field, showing one year of historic data": "Complete a data de plantio da parcela, mostrando 1 ano de dados históricos",
  "Fill the field form": "Preencher o formulário de talhão",
  "Fill the form to apply for all our services": "Preencha o formulário para solicitar todos os nossos serviços",
  "filtered field": "talhão filtrado",
  "filtered fields": "talhões filtrados",
  "Filters": "Filtros",
  "final_weight": "Peso final",
  "fitopatógenos ": "Fitopatógenos",
  "Flight Slider": "Voo arremessador",
  "FLIGHT_FILTER": "Comparação temporal",
  "flights type": "Tipo de vôos",
  "fmsavi": "FMSAVI",
  "for everyone": "para todos",
  "for me": "para mim",
  "forecasts": "Previsões",
  "formulario de abonado": "Formulário de fertilização",
  "formulario de dosis": "Formulário de dose",
  "fósforo foliar": "Fósforo da Folha",
  "fr": "Francius",
  "Fraction": "Fração",
  "free": "livre",
  "from": "De",
  "fruits_100_weight": "Peso de 100 frutas",
  "fruits_actual1_weight": "Peso de fruta actual",
  "Fuente de informacion": "Fonte de informação",
  "gallery": "galeria",
  "gauging date": "Data amostragem",
  "GENERAL": "GENÉRICO",
  "generation error": "erro de geração",
  "geometry": "geometria",
  "get a free trial": "provar grátis",
  "Get back to editor": "Volte para o editor",
  "global files": "Baixar arquivos PDF e CSV de pré-processamento, treinamento e previsão",
  "global layer": "Camada global",
  "gm_cut_number": "Número de cortes",
  "gm_data_generation": "Data do satélite (semanas)",
  "gm_day_cycle": "Dias de cultivo",
  "gm_harvest_estimated_date": "Data de colheita prevista (semanas)",
  "gm_hemav_atr_estimation": "Estimativa de ATR",
  "gm_hemav_pol_estimation": "Estimativa de polarização",
  "gm_hemav_prodph_estimation": "Estimativa de produtividade",
  "gm_hemav_realprodph_lastseason": "Produtividade real da zafra anterior",
  "gm_hemav_sac_estimation": "Estimativa de sacarose",
  "gm_luvi_date_generation": "Data do satélite - LUVI (semanas)",
  "gm_luvi_day_cycle": "Dias de colheita (NDVI-LUVI)",
  "gm_luvi_mean": "Vigor vegetativo (NDVI-LUVI)",
  "gm_luvi_ndvi_std": "Variabilidade (NDVI-LUVI)",
  "gm_lws_mean": "Estresse hídrico (LWS)",
  "gm_ndmi_date_generation": "Data do satélite - NDMI (semanas)",
  "gm_ndmi_day_cycle": "Dias de colheita (NDMI)",
  "gm_ndre_date_generation": "Data do satélite - NDRE (semanas)",
  "gm_ndre_day_cycle": "Dias de colheita (NDRE)",
  "gm_season_label": "Safra",
  "gm_sub_type_name": "Variedade",
  "gm_type_name": "Cultivo",
  "go to field": "Va para o talhão",
  "grid": "rede",
  "harvest date": "Data Colheita",
  "harvest planning": "Planejamento de colheita",
  "HARVEST_PLANNING_TOOL": "Planejamento da colheita",
  "HARVEST_PREDICTION": "Previsão de colheita",
  "hasta": "Até",
  "Hectare": "Hectare",
  "Hemav Default": "Pré-definido Hemav",
  "HEMAV Default": "Pré-definido HEMAV",
  "Here, you can play with the preview of your different layers.": "Aqui, você pode jogar com a pré-visualização de suas diferentes camadas",
  "hide": "ocultar",
  "Hide All Layers": "Ocultar todas as camadas",
  "hide filter list": "Esconder lista de filtros",
  "Hide Global Layer": "Ocultar Camada Global",
  "Hide Measure Tools": "Ocultar ferramentas de medição",
  "high": "Alto",
  "high level": "Alto",
  "high need": "Mais necessidades",
  "higher clay content": "Maior conteúdo de argila",
  "humedad de suelo - beta (%)": "Umidade do solo - Beta (%)",
  "humedad vegetativa (ndmi)": "Umidade Vegetativa (NDMI)",
  "Hybrid View": "Vista híbrida",
  "hydric status": "Estado Hídrico - NDMI",
  "hydric status drone": "Estado hidrico - Drone",
  "If foliar sampler haven't been made, the date you must enter is the current": "Se as extensões foliares não foram feitas, a data que você deve inserir é a data atual",
  "If you have two or more flights, you can compare them temporally using this tool.": "Se você tem dois ou mais voos, você pode compará-los temporariamente usando esta ferramenta.",
  "If you have undone an action, you can press this button to redo it.": "Se tiver desfeito uma ação, pode pressionar este botão para refazer.",
  "image": "imagem",
  "Impacto estimado": "Impacto estimado",
  "In this tab, you can download the generated PDFs.": "Nesta aba, você pode baixar os PDFs gerados.",
  "individuals_num": "Número de indivíduos afectados",
  "infection": "Infecção",
  "Information": "informação",
  "informe": "Relatório",
  "integración con maquinaria": "Integração com o maquinário",
  "Invalid credentials": "Credenciais inválidas",
  "invalid date": "Data inválida",
  "Invalid email": "Email inválido",
  "invest": "Invista",
  "Irrigation recommendation": "Recomendação de irrigação",
  "Irrigation type": "Tipo de irrigação",
  "It is commonly adjusted to establish the amount of fertilizer or seeds to be applied per unit area": "Comumente é ajustado para estabelecer a quantidade de fertilizante ou sementes a serem aplicados por unidade de superfície.",
  "item_num": "Número ítens",
  "Keep selection": "Manter seleção",
  "label": "01.Rótulo",
  "Label": "Etiqueta",
  "languaje": "Idioma",
  "last sample": "Ultimo amostragem",
  "lat": "Latitude",
  "layers": "Camadas",
  "layers estrés hídrico (lws)": "Layers estresse hídrico (LWS)",
  "layers status": "Estado de layers",
  "Legal limitation": "Limitação legal",
  "leptosphaeria_sacchari_g1": "leptosphaeria sacchari \"mancha anular\" - g1",
  "leptosphaeria_sacchari_g2": "leptosphaeria sacchari \"mancha anular\" - g2",
  "leptosphaeria_sacchari_g3": "leptosphaeria sacchari \"mancha anular\" - g3",
  "leptosphaeria_sacchari_g4": "leptosphaeria sacchari \"mancha anular\" - g4",
  "leptosphaeria_sacchari_g5": "leptosphaeria sacchari \"mancha anular\" - g5",
  "letter only": "Apenas letras",
  "linear meters": "metros lineares",
  "líneas de cultivo": "Linhas de Plantio",
  "list": "lista",
  "loading": "Carregando",
  "location": "Localização",
  "Log in": "Entrar",
  "logout": "Desconectar",
  "lon": "Longitude",
  "looks like its taking too long": "parece que está demorando muito",
  "low": "Baixo",
  "low level": "Baixo",
  "low need": "Menos necessidades",
  "lower clay content": "Menor conteúdo de argila",
  "LUVI variability": "Variabilidade LUVI",
  "Machines Type": "Tipo de máquinas",
  "main_macronutrient": "Macronutriente principal",
  "malas hierbas": "Ervas Daninhas",
  "malas hierbas - hoja estrecha": "Ervas Daninhas - Folha Estreita",
  "malas hierbas - hoja larga": "Ervas Daninhas - Folha Larga",
  "malas hierbas - zonificado": "Ervas Daninhas - Setorizado",
  "malas hierbas shps": "Ervas Daninhas SHPs",
  "malas hierbas shps - hoja estrecha": "Ervas Daninhas SHPs - Folha Estreita",
  "malas hierbas shps - hoja larga": "Ervas Daninhas SHPs - Folha Larga",
  "malas hierbas shps - zonificado": "Ervas Daninhas SHPs - Setorizado",
  "Manage report": "Administrar relatório",
  "max 2 years": "2 anos no máximo",
  "max comparable fields": "Numero maximo de talhões comparables",
  "maximo de variacion": "Variação máxima",
  "máximo vigor dos meses (ndvi)": "Vigor máximo de dois meses (NDVI)",
  "máximo vigor un año (ndvi)": "Vigor máximo de um ano (NDVI)",
  "Maximun cloud coverage": "Nebulosidade máxima",
  "mean": "Meia",
  "Measures tool tutorial": "Tutorial: Ferramentas de Medição",
  "Measures tools": "Ferramenta de medição",
  "media de faltas": "Média de Falhas",
  "medias": "Médias",
  "medio pot prod": "Médio Pot Prod",
  "medium": "Médio",
  "medium clay content": "Conteúdo médio de argila",
  "medium level": "Médio",
  "medium_weight_bigSize": "Peso de frutos grandes",
  "medium_weight_mediumSize": "Peso meio de frutos médio",
  "medium_weight_smallSize": "Peso meio de frutos pequenos",
  "Mensual limit": "Limite Mensal",
  "Message": "Mensagem",
  "METEOROLOGICAL_STATISTICS": "Estadístiques meteorolicalgiques",
  "mode": "Modo",
  "model type": "Tipo de modelo",
  "Move the slider": "Mover o arremessador",
  "msavi": "MSAVI",
  "N_BUNCHES": "Número de cachos",
  "Name": "Nome",
  "nameless field": "Campo sem nome",
  "Natural Breaks (Jenks)": "Rupturas Naturais (Jenks)",
  "ndre": "NDRE",
  "ndvi": "NDVI",
  "ndvi real": "NDVI Real",
  "ndvi-curvas de nivel": "NDVI-Curvas de Nível",
  "Network request failed": "Erro de conexão",
  "new sample": "amostra nova",
  "New sample created.": "Nova amostra criada.",
  "NITRO": "Nitrogênio",
  "nitrogen chlorophyll (ndre)": "Nitrogênio - Clorofila (NDRE)",
  "nitrogen_assimilable": "Nitrogênio assimilável",
  "nitrógeno foliar": "Nitrogênio foliar",
  "Nivel de estres": "Nivel de estresse",
  "no data": "Não há dados",
  "no data available": "Não há dados disponíveis ainda",
  "no data with current selection": "Sem dados com seleção atual",
  "No fields selected": "No fields selected",
  "no fields to clear": "Não há campos para limpar",
  "NO_DELIVERABLES_IMAGES_MESSAGE": "Não há imagens para este dia.",
  "Not a user yet ?": "Não é usuario ?",
  "not enough data to display this chart": "Não há dados suficientes para apresentar o gráfico",
  "Not supported by browser": "Não compatível com seu navegador",
  "notes": "notas",
  "Number only": "Somente numero",
  "Numero vuelo": "Numero de voo",
  "observations": "observaciones",
  "offline access": "acesso offline",
  "offline mode": "Modo offline",
  "oil_yield": "Rendimiento de óleo",
  "ok": "Ok",
  "Once you are done, click this button to save the changes.": "Quando terminar, clique neste botão para salvar as alterações",
  "open": "aberto",
  "Open": "Abrir",
  "Open selection": "Abrir seleção",
  "open temporal comparison": "Abrir comparação temporal",
  "open_selected": "Abrir seleção",
  "optimize": "Otimize",
  "or": "ou",
  "Order by a - z": "Ordenar a - z",
  "Order by distance to the field": "Ordenar por distância",
  "Order by z - a": "Ordenar z - a",
  "ourDeviation": "Desvio do Hemav",
  "ourProductionEstimation": "Estimativa de produção do Hemav",
  "Parcela": "Talhão",
  "Password": "Password",
  "pb": "Chumbo",
  "pcd": "PCD",
  "pcd arbol": "PCD por Árvore",
  "pcd interpolado": "PCD Interpolado",
  "pcd row": "PCD por Linha",
  "pdf": "PDF",
  "pending": "pendente",
  "phospohorus": "Fosforo",
  "phosporus": "Fósforo",
  "photo": "foto",
  "photoid": "ID foto",
  "photoId": "Id da imagen",
  "picture_path": "Imagen",
  "plague": "Plague",
  "planned dosage": "Dose prevista",
  "Plant emitters": "Emissores de plantas",
  "plantation date": "Data da plantação",
  "Plantation date": "Data da plantação",
  "plantation_end_date_range": "Rango da data de plantío",
  "plantation_start_date_range": "Rango da data de plantío",
  "Plantations": "Plantações",
  "plants": "Amostragem",
  "Plants distance": "Distância das plantas",
  "plants_density_range": "Faixa de densidade da planta",
  "Please wait or order one to have data to visualized.": "Por favor, aguarde ou peça os dados para visualizar.",
  "Please, choose a field variety": "Por favor, selecione uma variedade de cultivo",
  "Please, choose the field owner": "Por favor, selecione um cliente",
  "plural selected": "seletionados",
  "POL": "Polarização",
  "Polygon drawing": "Dibujar Polígono",
  "positive number only": "Apenas número positivo",
  "positive number only and minor 100": "Apenas número positivo e menor ou igual a 100",
  "pot. horticolar 90": "pot. horticolar 90",
  "pot. otros cultivos 150": "pot. otros cultivos 150",
  "pot. otros cultivos 90": "pot. otros cultivos 90",
  "potasio foliar": "Potássio da Folha",
  "potencial productivo histórico (ndvi)": "Historical productive potential (NDVI)",
  "precio": "Preço",
  "PRED_TECH": "PredTech",
  "Predefined ranges by HEMAV which are generally appropriate for the majority of fields": "Faixas predefinidas pela HEMAV que são geralmente apropriadas para a maioria dos campos.",
  "prediction": "Predição",
  "preprocessing": "Pré-processamento",
  "Press this button to add a new field.": "Pressione este botão para adicionar novo talhão.",
  "Pressing this button lets you go back to the field view. ": "Pressionar este botão permite voltar para a vista de talhão.",
  "Preview": "Pré-visualização",
  "price Kg": "Preço por kg",
  "primer vuelo": "Primeiro vôo",
  "PROD": "Rendimento por unidade de área",
  "producción esperada": "Produção Esperada",
  "product Kg": "Kg do produto",
  "product Kg is mandatory": "Kg do produto é um campo obrigatório",
  "Production estimate": "Estimativa de produção",
  "production estimate (units)": "Estimativa de produção médio (Kg/Ha)",
  "profile": "Perfil",
  "protein": "Proteína",
  "puccinia_sp_g1": "puccinia sp \"roya\" - g1",
  "puccinia_sp_g2": "puccinia sp \"roya\" - g2",
  "puccinia_sp_g3": "puccinia sp \"roya\" - g3",
  "puccinia_sp_g4": "puccinia sp \"roya\" - g4",
  "puccinia_sp_g5": "puccinia sp \"roya\" - g5",
  "Quick start with Azure": "Acesso rápido com Azure",
  "Random fertilization": "Adubação aleatória",
  "Random seedling": "Mudas aleatórias",
  "Range": "Rango",
  "Ranges adjusted to the index": "Intervalos ajustados ao índice",
  "Ranges of the same size will be generated according to the minimum and maximum values of the image. In cases where the image is very homogeneous, it is likely that no differentiation will be seen.": "Faixas do mesmo tamanho serão geradas de acordo com os valores mínimo e máximo da imagem. Nos casos em que a imagem seja muito homogênea, é provável que nenhuma diferenciação seja vista.",
  "realProduction": "Produção real",
  "Recipients": "Destinatários",
  "recomendación abonado dosificado": "Recomendação Dose de Fertilizantes",
  "recomendación abonado zonificado": "Recomendação de Adubaçao Dosada",
  "Recomendacion de riego": "Recomendacion de riego",
  "recomendación riego": "Recomendação irrigação",
  "Redo Action": "Refazer Ação",
  "Related date": "Data Relacionada",
  "relative nitrogen": "Nitrogênio relativo",
  "Rendimiento graso ( % )": "Teor de Gordura ( % )",
  "report": "Relatório",
  "Report sent correctly": "Relatório enviado corretamente",
  "report type": "Tipo",
  "Report type": "Tipo de relatório",
  "report_type_action_description": "Proposta de tratamentos ou ações a serem aplicadas",
  "report_type_annotation_description": "Diagnóstico: Análise realizada em campo",
  "report_type_inspection_description": "Análise em LAYERS (feche após analisar)",
  "report_type_result_description": "Análise dos resultados da solução ou tratamento aplicado.",
  "reporte agronómico": "Relatório Agronômico",
  "Reported by": "Reportado por",
  "Reported fields": "Talhões relatados",
  "reports manager": "Relatórios",
  "required": "requerido",
  "Required": "Requeridos",
  "Requires 3 characters minimun": "Requer um mínimo de 3 caracteres",
  "Reset": "Redefinir",
  "resiembra": "Replantio",
  "resolution": "Resolução",
  "resolution (only SHP)": "Resolução (apenas para SHP)",
  "retención de agua": "Retenção de água",
  "retry": "tentar novamente",
  "rgb": "RGB",
  "richness": "Riqueza",
  "riego zonificado": "Irrigação por Zoneada",
  "riesgo malherbología": "Risco de Plantas Danhinas",
  "Ruler": "Régua",
  "SAC": "Sacarose por unidade de área",
  "sample": "Amostra",
  "sample type": "tipo",
  "Sample was deleted.": "A amostra foi deletada.",
  "Sample was successfully modified.": "A amostra foi modificada com sucesso.",
  "sample_creation_date": "Data de criação",
  "samples": "amostragem",
  "satélite": "Satélite",
  "Satellite button tutorial": "Tutorial: Vista de Satélite",
  "Satellite View": "Vista de Satélite",
  "save": "Salvar",
  "Save field": "Guardar talhão",
  "save_": "salvar",
  "search": "Pesquisar",
  "Search": "Pesquisar",
  "season": "temporada",
  "Season label": "Etiqueta da estação",
  "season name": "nome da safra",
  "SEASON_EVOLUTION": "Evolução da Zafra",
  "Sector": "Setor",
  "sectores target": "Setores Target",
  "See? Now, clicking the button again lets you go back to the satellite view.": "Vê? Agora, clicar no botão novamente permite voltar para a visualização de satélite.",
  "seguimiento_cultivo.xlsx": "seguimiento_cultivo.xlsx",
  "segundo vuelo": "Segundo vôo",
  "Seleccione una parcela": "Selecione talhões",
  "Select": "Selecionar",
  "select a crop and sample": "Selecione um cultivo e uma amostra",
  "select all": "Selecionar tudo",
  "Select all": "Selecionar tudo",
  "select at least one field": "Selecione pelo menos um talhõe",
  "select fields by": "Selecionar talhões por",
  "select fields by variability range": "Selecionar talhões por variabilidade",
  "select fields to open temporal comparison": "Selecione campos para abrir comparação temporal",
  "select one": "Escolha um",
  "select one customer please": "Selecione um cliente por favor",
  "select one subtype please": "Selecione um subtipo por favor",
  "select one type please": "selecione um modelo por favor",
  "select sample base layer and date": "selecione camada de base e data para a amostra",
  "select sample location": "selecione o local da amostra",
  "select season": "selecionar safra",
  "selected": "selecionadas",
  "Selected fields names": "Talhões selecionados",
  "Selected fields samples": "Amostragem de talhões selecionadas",
  "Selection includes too much information, try selecting a shorter date range": "A selecção inclui informações em excesso, tente um intervalo de datas mais curto",
  "Send": "Mandar",
  "Send report": "Enviar relatório",
  "settings": "Ajustes",
  "Share fields report": "Relatório de compartilhamento de talhõe / s",
  "show all": "mostrar tudo",
  "Show All Layers": "Mostrar todas as camadas",
  "Show current season only": "Mostrar apenas a temporada atua",
  "show fields with no data": "Mostrar campos sem dados",
  "show filter list": "Mostrar lista de filtros",
  "Show Global Layer": "Mostrar Camada Global",
  "Show Measure Tools": "Mostrar ferramentas de medição",
  "shp-tooltip": "Estes números podem ser modificados de acordo com as necessidades de uso e estão associados a cada classe de geometria na tabela de atributos SHP exportada. É comumente ajustado para definir a quantidade de fertilizante ou semente a ser aplicada por unidade de superfície.",
  "singular selected": "seletionado",
  "Slice tool only works on Polygons and Circle": "Ferramenta de corte só funciona em Polígonos e Círculo",
  "Slider Tool": "Ferramenta deslizante",
  "soil": "Solo",
  "soil_type": "Tipo de solo",
  "sólo para la generación de SHP": "Somente para a geração SHP",
  "Sort": "Ordem",
  "sort by date": "ordenar por data",
  "Start date": "Data de início",
  "static layers": "camadas fixas",
  "Statistics": "Seguimiento",
  "status": "Estado",
  "stem_num": "Númbero de hastes",
  "Streets distance": "Distância das ruas",
  "Subtype": "Subtipo",
  "Suc/Ha": "Sac/Ha",
  "sum_of_bases": "Soma das bases",
  "Superficie ( Ha )": "Superficíe Talhão ( Ha )",
  "Superficie Sector ( Ha )": "Superficíe Setor ( Ha )",
  "surfaceUnit": "Unidades de superfície",
  "switch between all samples and current season samples": "alternar entre todas as amostras e as amostras da temporada atual",
  "Switch to hybrid map": "Mudar para mapa híbrido",
  "Switch to Mapbox": "Mudar para o mapa do satélite2",
  "Switch to satellite map": "Mudar para mapa de satélite",
  "Switch to Terrain map": "Mudar para o mapa do terreno",
  "target date": "Data de referência",
  "target multiparcela": "Target Multitalhão",
  "tell us": "Diga-nos",
  "Temporal Comparison tutorial": "Tutorial: Comparação Temporal",
  "textura del suelo": "Textura do solo",
  "The date should be in DD/MM/YYYY format": "A data deve ser válida (DD/MM/AAAA)",
  "The distance must be in meters": "A distância deve estar em metros",
  "the extra parameters will be removed": "os parâmetros addicionales serão excluídos",
  "the following layers are no longer available in offline mode, download again if necessary": "essas camadas não estão disponíveis no modo offline, baixe-as novamente se necessário",
  "The production must be numeric/decimal": "A produção deve ser um valor numérico",
  "The right column shows the total amount to be applied according to the value entered per class, and the total per plot at the bottom": "A coluna da direita mostra a quantidade total a ser aplicada de acordo com o valor introduzido por classe, e o total por parcela na parte inferior.",
  "The sample location must be inside one of the fields.": "A localização da amostra deve estar dentro de um dos campos.",
  "There are no samples to download": "Não há amostras para baixar",
  "There is no operated flight on this field.": "Ainda não existem resultados processados neste talhão",
  "Thermal Accumulation": "Acumulação Térmica",
  "These numbers can be modified according to application needs and are associated with each geometry in the attribute table of the exported SHP": "Esses números podem ser modificados de acordo com as necessidades da aplicação e são associados a cada geometria na tabela de atributos do SHP exportado.",
  "This action will delete the field and all its related data": "Esta ação exculuirá o talhão e todas as informações relacionadas",
  "this action will modify your field selection": "Esta ação modificará sua seleção de talhões",
  "this action will open field view and lose current field selection": "Esta açao obre a visão de mapa de este talhão e vai perder a seleçao de talhões",
  "This button allows you to draw lines or polygons to measure a distance or area.": "Este botão permite desenhar linhas ou polígonos para medir uma distância ou área.",
  "This button allows you to switch to the hybrid view.": "Este botão permite alternar para a visão híbrida",
  "This can take a long time": "Isso pode levar muito tempo",
  "This field is not downloaded.": "Você não baixou este campo.",
  "This tool lets you cut polygons. Use single clicks to draw the line and a double click to finish drawing.": "Esta ferramenta permite cortar polígonos. Use um único clique para desenhar a linha e um clique duplo para finalizar o desenho.",
  "This tool lets you draw polygons. Try to draw a simple one. Make sure you close it!": "Esta ferramenta permite desenhar polígonos. Tente desenhar um simples. Certifique-se de fechá-lo!",
  "This tool lets you measure the distance between a set of points. Use single clicks to set the points and a double click to finish drawing.": "Esta ferramenta permite medir a distância entre um conjunto de pontos. Use um clique simples para definir os pontos e um clique duplo para finalizar o desenho.",
  "Those fields are mandatory": "Esses talhões são obrigatórios",
  "TIME_COMPARISON_DROP_ZONE": "Arraste aqui...",
  "TINTA BARROCA": "TINTA BARROCA",
  "TINTA FRANCISCA": "TINTA FRANCISCA",
  "TINTO": "TINTO",
  "TINTO CÃO": "TINTO CÃO",
  "tipo de dosificacion": "Tipo de dosagem",
  "to": "Até",
  "today": "hoje",
  "todos": "Todos",
  "tons of cane - annual vision": "Toneladas de Cana - Visão Anual",
  "Total": "Total",
  "total production": "Produção total (Kg)",
  "total_fruits_bigSize": "frutos grandes totais",
  "total_fruits_mediumSize": "Frutos médio totais",
  "total_fruits_smallSize": "Frutos pequenos totais",
  "total_weight": "Peso total",
  "total_weight_bigSize": "Peso total de frutos grandes",
  "total_weight_canes": "Peso total de cana",
  "total_weight_mediumSize": "Peso total de frutos médio",
  "total_weight_smallSize": "Peso total de frutos pequenos",
  "TOURIGA FRANCA": "TOURIGA FRANCA",
  "training": "Treinamento",
  "TRINCADEIRA": "TRINCADEIRA",
  "Try our mobile app for a better experience": "¡Utiliza nuestra APP para una experiencia perfecta!",
  "type": "Cultivo",
  "Type": "Modelo",
  "type here your notes": "digite aqui suas notas",
  "Type your text": "Digite o texto",
  "type_sampler": "Tipo de amostragem",
  "Undo Action": "Desfazer Ações",
  "unidades": "Unidades",
  "unitSystem": "Unidades",
  "Unknown": "Desconhecido",
  "unnamed field": "campo sem nome",
  "unsaved changes will be lost": "as alterações não salvas serão perdidas",
  "update sample": "atualizar amostra",
  "updated": "Atualizado",
  "Use this buttons to select which layer to compare.": "Use este botão para selecionar as camadas você deseja comparar.",
  "Use this tool to compare two flights temporally. To start, drag one of the flights into the one you want to compare it with.": "Use esta ferramenta para comparar dois voos temporariamente. Para começar, arraste um dos voos para o qual você deseja comparar.",
  "Use this tool to freely move the created polygons.": "Use esta ferramenta para mover livremente os polígonos criados.",
  "used": "usado",
  "user": "Utilizadores",
  "Username": "Utilizador",
  "Value": "Valor",
  "variabilidad multiparcela": "Variabilidade Multitalhão",
  "variabilidad parcela (vra)": "Variabilidade talhão (VRA)",
  "variability": "Variabilidade",
  "Variedad": "Variedade",
  "vegetative growth": "Crescimento Vegetativo",
  "vegetative growth per day": "Crescimento Vegetativo Cumulativo Diário",
  "vegetative growth variability": "Variabilidade do Crescimento Vegetativo",
  "Ver detalles": "Ver detalhes",
  "very high": "Muito alto",
  "very high level": "Muito alto",
  "very low": "Muito baixo",
  "very low level": "Muito baixo",
  "view": "visão",
  "vigor": "VIGOR",
  "vigor a nivel árbol": "Vigor / planta",
  "vigor acumulado dos meses (ndvi)": "Vigor acumulado dois meses (NDVI)",
  "vigor acumulado un año (ndvi)": "Vigor acumulado um ano (NDVI)",
  "vigor vegetativo": "Vigor vegetativo",
  "vigor vegetativo - pcd": "Vigor Vegetativo - PCD",
  "vigor vegetativo (ndvi)": "Vigor Vegetativo (NDVI)",
  "vigor vegetativo (ndvi) - fases tempranas": "Vigor Vegetativo (NDVI) - Estágios Iniciais",
  "vigor zonificado": "Vigor zoned",
  "VINHÃO": "VINHÃO",
  "Visibility": "Visibilidade",
  "Vulnerable area": "área vulnerável",
  "wait": "aguardar",
  "warning": "Atenção",
  "warning map": "Mapa de alerta",
  "Water level": "Nível de água",
  "We are processing the data for your flight. Results will be available soon.": "Estamos processando os dados do vôo, por favor espere até estarem disponíveis",
  "weeds area": "Área de plantas daninhas",
  "week": "Semana",
  "weight_1": "Peso 1",
  "weight_2": "Peso 2",
  "weight_3": "Peso 3",
  "weight_4": "Peso 4",
  "weight_5": "Peso 5",
  "With this tool you can draw circles. Click to place the center, and click again to choose the radius.": "Com esta ferramenta, você pode desenhar círculos. Clique para posicionar o centro e clique novamente para escolher o raio.",
  "With this tool, you can draw a polygon with 3 or more points to measure its area.": "Com esta ferramenta, você pode desenhar um polígono com 3 ou mais pontos para medir sua área",
  "without warning": "Sem aviso",
  "Wrong credentials": "Credenciais inválidas",
  "year": "Ano",
  "years": "Ano",
  "You can change here the information of the new field.": "Você pode alterar aqui as informações do novo talhão",
  "You can move this bar to compare the layers temorally.": "Use esta ferramenta para comparar dois camadas temporariamente.",
  "You can only compare dates from the same sources": "Você somente pode comparar datas das mesmas fontes",
  "You can use this button at any moment to undo your last actions.": "Você pode usar este botão a qualquer momento para desfazer suas últimas ações.",
  "You can use this slider to change the opacity of the selected layer.": "Você pode usar este controle deslizante para alterar a opacidade da camada selecionada.",
  "You cannot create field because you don't have any customers. Please ask us for adding ones": "O talhão não pode ser criado, porque não tem clientes. Por favor, peça-nos para adicioná-los.",
  "You must enter a decimal": "Você deve inserir um decimal",
  "You must enter an integer": "Você deve digitar um inteiro",
  "You must select an irrigation type": "Você deve selecionar um tipo de irrigação",
  "You must type a field name": "Deve digitar um nome do talhão",
  "You must type a text": "Debe digitar um texto",
  "Your field has been successfully deleted.": "O talhão foi apagado corretamente",
  "Your LAYERS version has expired. Click to update.": "Sua versão de LAYERS expirou. Clique para atualizar."
}
