/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import find from 'lodash/find'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import toLower from 'lodash/toLower'
import toNumber from 'lodash/toNumber'
import upperFirst from 'lodash/upperFirst'
import union from 'lodash/union'

import withTranslator from '../../HOCComponents/withTranslator'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import { deliverablesFormResolutionOptions, TRACK_EVENTS } from '../../../constants'
import toString from 'lodash/toString'
import StyledSelect from '../../UIComponents/StyledSelect'
import StyledTextInput from '../../UIComponents/StyledTextInput'
import { UIStyles } from '../../../theme'
import { StyledIconButtonWithTooltip } from '../../SearchComponents/GroupItem'
import { Switch, FormControlLabel } from '@mui/material'
import { MySpinningBalls } from '../../PagesComponents/LoadingPageView'
import { convertHectares } from '@layers-frontend/commons/conversions/index'
import { trackEvent } from '../../analytics/analytics'

const Wrapper = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ClassesHeader = styled.div`
  display: flex;
  color: white;
  width: 100%;
  align-items: center;
`

const ClassesTotal = styled.div`
  display: flex;
  color: white;
  width: 100%;
  justify-content: flex-end;
  color: ${themeColors.vomitColor};
`

const ClassesRow = styled.div`
  display: flex;
  align-items: center;
`

export const StyledButton = styled.input`
  width: 170px;
  color: ${themeColors.darkBlue};
  border: none;
  background-color: ${themeColors.vomitColor};
  padding: 1rem 2rem;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 600;
  border: none;
  border-radius: 0.3rem;
`

const Form = styled.form`
  width: 100%;
  background-color: transparent;
  padding: 5px;
  margin-block-end: 0;
`
const ColorSquare = styled.div`
  height: 1.8rem;
  width: 1.9rem;
  border-radius: 3px;
  background-color: ${props => props.color};
`

const Section = styled.section`
  border-radius: 6px;
  background-color: rgba(39, 76, 96, 0.53);
  padding: 5px 15px 5px 15px;
  position: relative;
`

const SaveFileContainer = styled.div`
  display: flex;
  align-items: baseline;
  width: 93%;
  margin: 0 0 0 14px;
`

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background: rgba(8, 44, 63, 0.8);
  border-radius: 6px;
`

const tableWidths = {
  0: '27%',
  1: '27%',
  2: '20%',
  3: '12%',
  4: '14%'
}

const getSeasonIdsWithAccessToSelectedBaseLayer = (selectedFlightGroupSeasonIds, selectedBaseLayer, baseFileNamesBySeasonId) => {
  return reduce(
    selectedFlightGroupSeasonIds,
    (accumulator, seasonId) => {
      return includes(baseFileNamesBySeasonId[seasonId], selectedBaseLayer) ? [...accumulator, seasonId] : accumulator
    },
    []
  )
}

/*  OPTIONAL[classes]": "TYPE[int], OPTIONS[2-10], DEFAULT[5] */
const _classes = [2, 3, 4, 5, 6, 7, 8, 9, 10]
/* OPTIONAL[mode]": "TYPE[string], OPTIONS[fixed, equal, natural], DEFAULT[fixed] */
const _modes = [
  { label: 'Hemav Default', value: 'fixed', selected: false },
  { label: 'Equidistant ranges', value: 'equal', selected: false },
  { label: 'Ranges adjusted to the index', value: 'natural', selected: false }
]

function FileGeneratorForm({
  t,
  formatType,
  fetchLayerClasses,
  downloadLayer,
  selectedFieldsAvailablePdfBaseLayers,
  selectedFieldsAvailableShpBaseLayers,
  selectedFieldsAvailablePdfSeasonLayers,
  selectedFieldsAvailableShpSeasonLayers,
  selectedFieldsAvailableDroneBaseLayers,
  baseFileNamesBySeasonId,
  selectedFlightGroupSeasonIds,
  fieldsNames,
  language,
  surfaceUnit,
  selectedFlightGroupFlights,
  isSatOrRadarSelected
}) {
  const [classes, setClasses] = useState([])
  const [totalSum, setTotalSum] = useState(null)
  const [loadingClasses, setLoadingClasses] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm()

  const rawTabAvailableBaseLayers = isSatOrRadarSelected
    ? union(
        selectedFieldsAvailablePdfBaseLayers,
        selectedFieldsAvailablePdfSeasonLayers,
        selectedFieldsAvailableShpBaseLayers,
        selectedFieldsAvailableShpSeasonLayers
      )
    : selectedFieldsAvailableDroneBaseLayers

  const selectedFieldsAvailableBaseLayers = {
    shp: [...selectedFieldsAvailableShpBaseLayers, ...selectedFieldsAvailableShpSeasonLayers],
    pdf: [...selectedFieldsAvailablePdfBaseLayers, ...selectedFieldsAvailablePdfSeasonLayers],
    raw: rawTabAvailableBaseLayers
  }

  const extensions = {
    shp: 'zip',
    pdf: 'pdf',
    raw: 'zip'
  }

  const watchBaseLayer = watch('baseLayer')
  const watchMode = watch('mode', _modes[0].value)
  const watchClasses = watch('classes')

  const isShpForm = formatType === 'shp'
  const isPDFForm = formatType === 'pdf'

  const onSubmit = data => {
    const baseLayerName = t(toLower(get(find(selectedFieldsAvailableBaseLayers[formatType], ['base_file_name', data.baseLayer]), 'name')))
    const classesToSubmit = map(classes, (currentClass, key) => {
      const value = toNumber(get(data, `class${key + 1}`))

      return { ...currentClass, value }
    })

    const seasonIdsWithAccessToSelectedBaseLayer = getSeasonIdsWithAccessToSelectedBaseLayer(
      selectedFlightGroupSeasonIds,
      data.baseLayer,
      baseFileNamesBySeasonId
    )
    const snapshots = reduce(
      seasonIdsWithAccessToSelectedBaseLayer,
      (accumulator, seasonId) => {
        const flight = find(selectedFlightGroupFlights, flight => includes(map(flight.seasons, 'id'), toString(seasonId)))
        const snapshotDate = get(flight, 'date')
        if (snapshotDate) {
          return [...accumulator, { season_id: seasonId, date: snapshotDate }]
        }
        return accumulator
      },
      []
    )

    const downloadParams = {
      ...data,
      snapshots,
      fieldsNames,
      baseLayerName,
      language,
      surfaceUnit,
      classes: classesToSubmit,
      formatType,
      mode: isPDFForm ? t(_modes.find(({ value }) => value === watchMode).label) : watchMode,
      extension: extensions[formatType],
      t
    }

    trackEvent(TRACK_EVENTS.FIELD_PANEL_FILE_GENERATOR_DOWNLOAD, {
      format: formatType,
      button: 'Download file',
      options: downloadParams,
      location: TRACK_EVENTS.FIELD_PANEL
    })

    downloadLayer(downloadParams)
  }

  useEffect(() => {
    if (watchBaseLayer) {
      setLoadingClasses(true)
      if (errorMessage) {
        setErrorMessage(undefined)
      }
      const seasonIdsWithAccessToSelectedBaseLayer = getSeasonIdsWithAccessToSelectedBaseLayer(
        selectedFlightGroupSeasonIds,
        watchBaseLayer,
        baseFileNamesBySeasonId
      )
      const snapshots = reduce(
        seasonIdsWithAccessToSelectedBaseLayer,
        (accumulator, seasonId) => {
          const flight = find(selectedFlightGroupFlights, flight => includes(map(flight.seasons, 'id'), toString(seasonId)))
          const snapshotDate = get(flight, 'date')
          if (snapshotDate) {
            return [...accumulator, { season_id: seasonId, date: snapshotDate }]
          }
          return accumulator
        },
        []
      )

      setClasses([])

      fetchLayerClasses({
        snapshots,
        baseLayer: toLower(watchBaseLayer),
        mode: watchMode,
        classes: watchClasses
      })
        .then(newClasses => {
          setLoadingClasses(false)
          setClasses(newClasses || [])
        })
        .catch(() => {
          setErrorMessage(t('error fetching classes'))
          setLoadingClasses(false)
          setClasses([])
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchBaseLayer, watchMode, watchClasses])

  const generateErrorText = (formFieldName, noText = false, errorType = 'Required') => {
    if (isEmpty(errors[formFieldName])) {
      return undefined
    }

    return noText ? true : `${t(errorType)}`
  }

  useEffect(() => {
    const newTotal = classes.reduce((acc, { value, surface }) => {
      const total = +(value * surface).toFixed(2)
      return total ? acc + total : acc
    }, 0)
    const convertedNewTotal = convertHectares(newTotal, surfaceUnit)
    setTotalSum(convertedNewTotal.toFixed(2))
  }, [classes, surfaceUnit])

  const filteredOptions = selectedFieldsAvailableBaseLayers[formatType].reduce((acc, { base_file_name, name }) => {
    if (watchMode === 'fixed' && name === 'DSM') {
      return acc
    }
    acc.push({ id: base_file_name, name: upperFirst(t(toLower(name))) })
    return acc
  }, [])

  const generateNumericalError = () => {
    for (const error in errors) {
      if (error.includes('class')) {
        return <p style={{ color: 'red', margin: '5px 0 0 0', fontSize: '12px' }}>{t('Class values must be numerical')}</p>
      }
    }
  }

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)} data-testid="file-gen-form">
        <Section style={(isShpForm || isPDFForm) && classes && classes.length ? { margin: '0px 0px 14px' } : {}}>
          <Controller
            name="baseLayer"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <StyledSelect
                fullWidth={false}
                value={field.value}
                onChange={field.onChange}
                options={filteredOptions}
                inputLabel={t('baseLayer')}
                testid="file-gen-base-layer"
                placeholder={t('baseLayer')}
                inputStyleDropdown={{ fontSize: '12px' }}
                errorText={generateErrorText('baseLayer', true)}
                sx={{ fontSize: '12px', svg: { right: '0px' }, width: '100%' }}
                inputStyle={{ color: field.value ? UIStyles.whiteColor : UIStyles.grayColor, fontSize: '12px', width: '100%' }}
                wrapperStyle={{
                  width: '100%',
                  marginTop: '5px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'end',
                  justifyContent: 'space-between',
                  height: '30px'
                }}
              />
            )}
          />
          {(isShpForm || isPDFForm) && (
            <>
              <ClassesRow data-testid="class-row" style={{ fontSize: '12px', alignItems: 'baseline', margin: '0px' }}>
                <Controller
                  name="mode"
                  control={control}
                  defaultValue={_modes[0].value}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <StyledSelect
                      fullWidth={false}
                      onChange={field.onChange}
                      options={map(_modes, ({ value, label }) => ({
                        id: value,
                        name: t(label)
                      }))}
                      defaultValue={_modes[0].value}
                      placeholder={_modes[0].label}
                      value={field.value}
                      inputStyle={{ color: field.value ? UIStyles.whiteColor : UIStyles.grayColor, fontSize: '12px', width: '100%' }}
                      inputStyleDropdown={{ fontSize: '12px' }}
                      wrapperStyle={{
                        width: '100%',
                        marginTop: '5px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'end',
                        justifyContent: 'space-between',
                        height: '30px'
                      }}
                      errorText={generateErrorText('mode', true)}
                      testid="file-gen-base-layer"
                      sx={{ fontSize: '12px', svg: { right: '0px' }, width: '100%' }}
                      inputLabel={t('mode')}
                    />
                  )}
                />
                <StyledIconButtonWithTooltip
                  tooltipTitle={
                    <div style={{ width: 300, whiteSpace: 'normal', textAlign: 'left', left: 100, padding: 10 }}>
                      <span style={{ fontSize: 12, fontWeight: 700 }}>{t('HEMAV Default')}:</span>
                      <br />
                      {t('Predefined ranges by HEMAV which are generally appropriate for the majority of fields')}
                      <br />
                      <br />
                      <span style={{ fontSize: 12, fontWeight: 700 }}> {t('Equidistant ranges')}:</span>
                      <br />
                      {t(
                        'Ranges of the same size will be generated according to the minimum and maximum values of the image. In cases where the image is very homogeneous, it is likely that no differentiation will be seen.'
                      )}
                      <br />
                      <br />
                      <span style={{ fontSize: 12, fontWeight: 700 }}>{t('Ranges adjusted to the index')}:</span>
                      <br />
                      {t('CREATE_RANGES')}
                    </div>
                  }
                  className="fa-solid fa-circle-info"
                  placement={'right'}
                  style={{ fontSize: '12px', width: '1em', color: 'white' }}
                />
              </ClassesRow>
              <ClassesRow data-testid="class-row" style={{ fontSize: '12px', alignItems: 'baseline' }}>
                <Controller
                  name="classes"
                  control={control}
                  defaultValue={5}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <StyledSelect
                      fullWidth={false}
                      onChange={field.onChange}
                      options={map(_classes, value => ({
                        id: value,
                        name: value.toString()
                      }))}
                      defaultValue={5}
                      value={field.value}
                      wrapperStyle={{
                        width: '100%',
                        marginTop: '5px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline'
                      }}
                      inputStyle={{ color: field.value ? UIStyles.whiteColor : UIStyles.grayColor, fontSize: '12px' }}
                      inputStyleDropdown={{ fontSize: '12px' }}
                      errorText={generateErrorText('classes', true)}
                      testid="file-gen-classes"
                      sx={{ fontSize: '12px', svg: { right: '0px' } }}
                      inputLabel={t('classes')}
                    />
                  )}
                />

                <Controller
                  name="soil"
                  control={control}
                  defaultValue=""
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Switch name="soil" />}
                      label={t('soil')}
                      labelPlacement="start"
                      sx={{ color: UIStyles.whiteColor, fontSize: '12px', whiteSpace: 'nowrap', fontWeight: 300, display: 'none' }}
                      disableTypography={true}
                    />
                  )}
                />
                <Controller
                  name="cloud"
                  control={control}
                  defaultValue=""
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Switch name="cloud" />}
                      label={t('cloud')}
                      labelPlacement="start"
                      sx={{ color: UIStyles.whiteColor, fontSize: '12px', whiteSpace: 'nowrap', fontWeight: 300, display: 'none' }}
                      disableTypography={true}
                    />
                  )}
                />
              </ClassesRow>
              <Controller
                name="resolution"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <StyledSelect
                    fullWidth={false}
                    onChange={field.onChange}
                    options={deliverablesFormResolutionOptions}
                    placeholder={t('resolution')}
                    value={field.value}
                    wrapperStyle={{
                      width: '100%',
                      marginTop: '5px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'end',
                      height: '30px'
                    }}
                    inputStyle={{ color: field.value ? UIStyles.whiteColor : UIStyles.grayColor, fontSize: '12px' }}
                    inputStyleDropdown={{ fontSize: '12px' }}
                    errorText={generateErrorText('resolution', true)}
                    testid="file-gen-resolution"
                    sx={{ fontSize: '12px', svg: { right: '0px' } }}
                    inputLabel={t('resolution')}
                  />
                )}
              />

              {errorMessage && <p style={{ color: 'red', margin: '5px 0 0 0', fontSize: '12px' }}>{errorMessage}</p>}
            </>
          )}
          {loadingClasses && (
            <LoadingContainer>
              <MySpinningBalls scale={0.5} marginTop={'0px'} />
            </LoadingContainer>
          )}
        </Section>

        {(isShpForm || isPDFForm) && classes && classes.length ? (
          <Section>
            <ClassesHeader>
              <p style={{ width: tableWidths[0], margin: 0, padding: 0, fontSize: '12px' }}>
                {t('Value')}{' '}
                <StyledIconButtonWithTooltip
                  tooltipTitle={
                    <div style={{ whiteSpace: 'normal', textAlign: 'left', padding: '10px' }}>
                      {t(
                        'These numbers can be modified according to application needs and are associated with each geometry in the attribute table of the exported SHP'
                      )}
                      <br />
                      <br />
                      {t('It is commonly adjusted to establish the amount of fertilizer or seeds to be applied per unit area')}
                      <br />
                      <br />
                      {t(
                        'The right column shows the total amount to be applied according to the value entered per class, and the total per plot at the bottom'
                      )}
                    </div>
                  }
                  className="fa-solid fa-circle-info"
                  placement={'right'}
                  style={{ fontSize: '12px', width: '1em', color: 'white' }}
                />
              </p>
              <p style={{ width: tableWidths[1], margin: 0, padding: 0, fontSize: '12px' }}>{t('Range')}</p>
              <p style={{ width: tableWidths[2], margin: 0, padding: 0, fontSize: '12px' }}>
                {t('Area')}, {`${surfaceUnit}`}
              </p>
              <p style={{ width: tableWidths[3], margin: 0, padding: 0, fontSize: '12px' }}>%</p>
              <p style={{ width: tableWidths[4], margin: 0, padding: 0, fontSize: '12px', textAlign: 'right' }}>{t('Total')}</p>
            </ClassesHeader>

            {classes.map((classElement, key) => {
              const { color, value, surface, fraction } = classElement

              const total = +(value * surface).toFixed(2)
              const classRanges = get(classElement, 'class')

              return (
                <ClassesRow data-testid="class-row" key={color} style={{ fontSize: '12px' }}>
                  <span data-testid="class-span" style={{ display: 'flex', alignItems: 'center', width: tableWidths[0], paddingBottom: '4px' }}>
                    <Controller
                      name={`class${key + 1}`}
                      defaultValue={value}
                      control={control}
                      rules={{ required: true, pattern: /^([-+] ?)?[0-9]+([.][0-9]+)?$/i }}
                      render={({ field }) => {
                        return (
                          <StyledTextInput
                            name={field.name}
                            value={field.value}
                            defaultValue={value}
                            onChange={e => {
                              const newValue = +e
                              const updatedClasses = classes.map((currentClass, index) => {
                                if (index === key) {
                                  return { ...currentClass, value: newValue }
                                }
                                return currentClass
                              })
                              setClasses(updatedClasses)
                              field.onChange(e)
                            }}
                            onBlur={field.onBlur}
                            placeholder={'0'}
                            autoFocus={true}
                            textFieldStyle={{
                              width: '40%',
                              margin: '0',
                              marginRight: '10px'
                            }}
                            inputProps={{ style: { fontSize: 12, height: '10px', textAlign: 'right' } }}
                            InputLabelProps={{ style: { fontSize: 12 } }}
                          />
                        )
                      }}
                    />
                    <ColorSquare color={color} />
                  </span>
                  <span style={{ color: 'white', width: tableWidths[1], height: '100%', fontSize: '12px' }}>{classRanges}</span>
                  {<span style={{ color: 'white', width: tableWidths[2] }}>{surface !== 0 ? convertHectares(surface, surfaceUnit).toFixed(2) : surface}</span>}
                  {<span style={{ color: 'white', width: tableWidths[3] }}>{(fraction * 100).toFixed(1)}</span>}
                  {
                    <span style={{ color: themeColors.vomitColor, width: tableWidths[4], textAlign: 'right' }}>
                      {isNaN(total) ? '' : total !== 0 ? convertHectares(total, surfaceUnit).toFixed(2) : total}
                    </span>
                  }
                </ClassesRow>
              )
            })}

            {totalSum ? (
              <ClassesTotal>
                <p style={{ borderTop: `1px solid ${themeColors.vomitColor}`, width: '46%', margin: 0, padding: 0, fontSize: '12px', textAlign: 'right' }}>
                  {totalSum}
                </p>
              </ClassesTotal>
            ) : null}

            {generateNumericalError()}
          </Section>
        ) : null}

        <SaveFileContainer data-testid="SaveFileContainer">
          <Controller
            name="filePrefix"
            control={control}
            defaultValue=""
            rules={undefined}
            disabled={loadingClasses}
            render={({ field }) => (
              <StyledTextInput
                wrapperStyle={{ width: '100%', margin: '3px 0 0px' }}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('File name')}
                margin={'dense'}
                disabled={loadingClasses}
                inputProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { fontSize: 12 } }}
                size="small"
              />
            )}
          />

          <StyledIconButtonWithTooltip disabled={loadingClasses} tooltipTitle={t('Download')} type={'submit'} className={'fa-solid fa-floppy-disk'} />
        </SaveFileContainer>
      </Form>
    </Wrapper>
  )
}

export default withTranslator(FileGeneratorForm)
