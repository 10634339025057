// prettier-ignore
export default {
  "% Fallas": "% Eșecuri",
  "% Mala Hierba": "% Buruieni",
  "ACELGA": "SALVIE",
  "ACELGA VERDE": "SALVIE VERDE",
  "action": "acțiune",
  "ag_accesses_the_viewer": "k. AG accesează vizualizatorul?",
  "AGUACATE": "AVOCADO",
  "AJO": "USTUROI",
  "ALBARICOQUE": "CAIS",
  "ALBARINO": "ALBARINO",
  "ALFALFA": "LUCERNĂ",
  "ALGODON": "BUMBAC",
  "aluminum": "Aluminiu",
  "aluminum_saturation": "Saturație de Aluminiu",
  "annotation": "anotație",
  "aphid_controller": "controlor afide",
  "aphid_infested": "infestat cu afide",
  "aphid_infested_leafs": "frunze infestate cu afide",
  "aphid_mite_total_leafs": "total frunze afide și acarieni",
  "APIO": "ȚELINĂ",
  "apparent_density": "Densitate aparentă",
  "ARANDANOS": "AFINE",
  "ARBOSANA": "ARBOSANA",
  "ARROZ": "OREZ",
  "assesor_name": "Nume evaluator",
  "atr": "Atr",
  "AVENA": "OVĂZ",
  "BARBECHO": "PÂRLOAGĂ",
  "BATAVIA": "BATAVIA",
  "beans_10_plants": "Plante de 10 fasole",
  "billaea_healthy_pupe": "billaea - pupe sănătoase",
  "billaea_larva": "billaea - larve",
  "billaea_parasitized_larva": "billaea - larve parazitate",
  "billaea_residual_pupe": "billaea - pupe reziduale",
  "bipolaris_sacchari_incidence": "bipolaris sacchari \"pată ochi\" - incidență",
  "bipolaris_sacchari_severity": "bipolaris sacchari \"pată ochi\" - severitate",
  "bipolaris_sacchari_total_infected_leafs": "bipolaris sacchari \"pată ochi\" - total frunze infectate",
  "BLANCO": "ALB",
  "blastobasis_healthy_chrysalis": "blastobasis - crisalide sănătoase",
  "blastobasis_larva_number": "blastobasis - număr larve",
  "blastobasis_residual_chrysalis": "blastobasis - crisalide reziduale",
  "boron": "Bor",
  "bouquet10roots_2_8cm": "02.Buchet 10 rădăcini (2-8cm)",
  "bouquet10roots_2cm": "01.Buchet 10 rădăcini (0-2cm)",
  "bouquet10roots_8cm": "03.Buchet 10 rădăcini (>8cm)",
  "brix": "Brix",
  "BROCOLI": "BROCCOLI",
  "bud_blastobasis_drilled": "muguri diatraea blastobasis perforați",
  "bud_dehydrated": "muguri deshidratați",
  "bud_diatrea_drilled": "muguri diatraea perforați",
  "bud_mechanical_damage": "muguri avariați mecanic",
  "bud_sprout": "muguri - lalas",
  "bud_total": "total muguri",
  "bud_turgid": "muguri turgizi",
  "CABERNET SAUVIGNON": "CABERNET SAUVIGNON",
  "CALABACIN": "DOVLECEL",
  "calcium": "Calciu",
  "calcium_saturation": "Saturație de Calciu",
  "CAÑA DE AZUCAR": "TRESTIE DE ZAHĂR",
  "CAQUI": "KAKI",
  "casida_afa_perc": "Cásida - AFA(%)",
  "casida_n_of_adults_in_50_plants": "Cásida - Nr. de adulți în 50 de plante",
  "casida_n_of_eggs_in_50_plants": "Cásida - Nr. de ouă în 50 de plante",
  "casida_n_of_large_larvae_in_50_plants": "Cásida - Nr. Larve mari 50 plante",
  "casida_n_of_small_larvae_in_50_plants": "Cásida - Nr. Larve mici 50 plante",
  "CAUCHO": "CAUCIUC",
  "CEBADA": "ORZ",
  "CEBADA HIBRIDA": "ORZ HIBRID",
  "CEBADA MALTERA": "ORZ MALTERA",
  "CEBOLLA": "CEAPĂ",
  "cercospora_20_100_spots_leaf": "Cercospora -F- 20-100 pete/frunză",
  "cercospora_50_afa": "Cercospora -L- 50% AFA",
  "cercospora_50_perc_leaves_ext_with_80_100_severity": "Cercospora -K- 50% frunze ext cu 80-100% severitate",
  "cercospora_75_afa": "Cercospora -M- 75%AFA",
  "cercospora_90_100_afa": "Cercospora -N- 90-100%AFA",
  "cercospora_healthy_plant": "Cercospora -A- Plantă sănătoasă",
  "cercospora_isolated_spots_on_some_leaves_10_15_sl_100_leaves": "Cercospora -D- Pete izolate pe unele frunze (10-50%SL/100 frunze).",
  "cercospora_isolated_spots_on_some_leaves_5_10_sl_100_leaves": "Cercospora -C- Pete izolate pe unele frunze (5-10%SL/100 frunze)",
  "cercospora_isolated_spots_on_some_leaves_minus_5_sl_100_leaves": "Cercospora -B- Pete izolate pe unele frunze (<5%SL/100 frunze)",
  "cercospora_leaf_necrosis_ext": "Cercospora -H- Necroză frunză EXT.",
  "cercospora_leaves_ext_almost_total_necrosis": "Cercospora -J- Frunze \"EXT\": aproape necroză totală",
  "cercospora_leaves_with_major_100_sports": "Cercospora -G- Frunze cu > 100 pete",
  "cercospora_minus_20_spots_leaf": "Cercospora -E- <20 pete/frunză",
  "cercospora_necrosis_on_most_leaves_int": "Cercospora -I- Necroză pe majoritatea frunzelor INT.",
  "cercospora_treatment": "Cercospora -o- Tratament",
  "CEREZA": "CIREȘ",
  "CESPED": "GAZON",
  "CHARDONNAY": "CHARDONNAY",
  "chromium": "Crom",
  "cic": "Cic",
  "CIRUELA": "PRUNĂ",
  "clay": "Argilă",
  "CLEMENTINA": "CLEMENTINĂ",
  "close": "închide",
  "COLZA": "RAPIȚĂ",
  "Conteo": "Numărătoare",
  "copper": "Cupru",
  "cotesia_cocon": "cotesia - coconi",
  "cotesia_parasitized_larva": "cotesia - larve parazitate",
  "cotyledon": "cotiledon",
  "current_ndre_status": "a. Stare curentă NDRE",
  "date_of_first_informative_email_sent": "j. Data trimiterii primului email informativ",
  "date_of_hemav_ndre_layer_screenshot": "h. Data capturii de ecran a stratului NDRE Hemav",
  "defoliator_leaf_evaluated": "defoliator - frunze evaluate",
  "defoliator_marasmia_sp_larva_alive": "defoliator - marasmia sp. - larve vii",
  "defoliator_marasmia_sp_larva_death": "defoliator - marasmia sp. - larve moarte",
  "defoliator_marasmia_sp_larva_per_stem": "defoliator - marasmia sp. - larve pe tulpină",
  "defoliator_mythimna_sp_larva_alive": "defoliator - mythimna sp. - larve vii",
  "defoliator_mythimna_sp_larva_death": "defoliator - mythimna sp. - larve moarte",
  "defoliator_mythimna_sp_larva_per_stem": "defoliator - mythimna sp. - larve pe tulpină",
  "defoliator_spodoptera_sp_larva_alive": "defoliator - spodoptera sp. - larve vii",
  "defoliator_spodoptera_sp_larva_death": "defoliator - spodoptera sp. - larve moarte",
  "defoliator_spodoptera_sp_larva_per_stem": "defoliator - spodoptera sp. - larve pe tulpină",
  "defoliator_stem_total": "defoliator - total tulpini",
  "depth": "Adâncime",
  "desconocido": "Necunoscut",
  "DESCONOCIDO": "NECUNOSCUT",
  "diatraea_saccharalis_larva_big": "diatraea sachharalis - larve mari",
  "diatraea_saccharalis_larva_healthy_chrysalis": "diatraea sachharalis - crisalide sănătoase",
  "diatraea_saccharalis_larva_medium": "diatraea sachharalis - larve medii",
  "diatraea_saccharalis_larva_residual_chrysalis": "diatraea sachharalis - crisalide reziduale",
  "diatraea_saccharalis_larva_small": "diatraea sachharalis - larve mici",
  "diatraea_saccharalis_larva_total": "diatraea sachharalis - total larve",
  "disease": "Boală",
  "Disease": "Boală",
  "disease_level": "08.Nivel Boală",
  "distance_between_rows": "03.Distanța între rânduri",
  "does_it_follow_the_recommendation": "f. Urmează recomandarea?",
  "Drip": "Picurare",
  "dry_weight": "Greutate uscată",
  "effective_cation_exchange_capacity": "Capacitate Efectivă de Schimb Cationic (CES)",
  "ESCAROLA": "CICOARE",
  "ESCAROLA LISA": "CICOARE NETEDĂ",
  "ESCAROLA RIZADA": "CICOARE CREȚĂ",
  "ESPARRAGO": "SPARANGHEL",
  "ESPINACA": "SPANAC",
  "estrés hídrico": "Stres hidric",
  "Estrés hídrico (LWS)": "Stres hidric (LWS)",
  "evaluator": "10.Nume evaluator",
  "Ex": "Ex.",
  "exchangeable_acidity": "Aciditate Intercambiabilă",
  "forecast_yield_sampling_1": "EȘANTIONARE RANDAMENT PREVIZIONAT 1",
  "forecast_yield_sampling_2": "EȘANTIONARE RANDAMENT PREVIZIONAT 2",
  "forecast_yield_sampling_3": "EȘANTIONARE RANDAMENT PREVIZIONAT 3",
  "forecast_yield_sampling_4": "EȘANTIONARE RANDAMENT PREVIZIONAT 4",
  "fresh_weight": "Greutate proaspătă",
  "FRIJOL": "FASOLE",
  "fruits_count": "Număr fructe",
  "FRUTAL": "FRUCT",
  "Generic": "Generic",
  "GEWURZTRAMINNER": "GEWURZTRAMINNER",
  "GIRASOL": "FLOAREA-SOARELUI",
  "gm_avg_ndvi": "Vigoare vegetativă (NDVI-Drone)",
  "gm_luvi_ndvi_mean": "Vigoare vegetativă (NDVI-LUVI)",
  "gm_ndmi_mean": "Umiditate vegetativă (NDMI)",
  "gm_ndre_mean": "Azot-Clorofilă (NDRE)",
  "gm_perc_overseeding": "% Goluri",
  "gm_perc_weeds": "% Buruieni",
  "GODELLO": "GODELLO",
  "GOLDEN DELICIOUS": "GOLDEN DELICIOUS",
  "GOLDEN REINDERS": "GOLDEN REINDERS",
  "Grams": "Grame",
  "GRANADA": "RODIE",
  "green_dots": "puncte verzi",
  "GRENACHE BLANC": "GRENACHE BLANC",
  "GUISANTE": "MAZĂRE",
  "HABA": "BOB",
  "height": "Înălțime",
  "HIBRIDO": "HIBRID",
  "high irrigation": "Stres hidric ridicat",
  "high_n_from_irrigation_water": "d. Azot ridicat din apa de irigare",
  "high_n_from_nitrogen_input": "e. Azot ridicat din aportul de azot",
  "high_n_from_previous_years": "c. Azot ridicat din anii precedenți",
  "higher level": "Nivel superior",
  "HIGO": "SMOCHIN",
  "Humedad Vegetativa (NDMI)": "Umiditate vegetativă (NDMI)",
  "humidity": "Umiditate",
  "humidity_100_beans": "Umiditate în 100 de fasole",
  "Imperial": "Imperial",
  "inspection": "inspecție",
  "internodes_blastobasis_damage_longitude": "internoduri blastobasis lungimea avariei",
  "internodes_blastobasis_drilled": "internoduri blastobasis perforate",
  "internodes_diatraea_damage_longitude": "internoduri diatrea lungimea avariei",
  "internodes_diatraea_drilled": "internoduri diatrea perforate",
  "internodes_evaluated": "total internoduri",
  "internodes_healthy": "internoduri sănătoase",
  "internodes_mechanical_damage": "avarii mecanice interne",
  "internodes_physiological_damage": "avarii fiziologice ale internodurilor",
  "iron": "Fier",
  "irrigation": "IRIGARE",
  "Irrigation": "Irigare",
  "irrigation_dosage": "Dozaj de irigare",
  "irrigation_uniformity": "Uniformitatea irigării",
  "KUMQUATS": "KUMQUATS",
  "language": "Limba sistemului",
  "larva_number_big": "stadiul larvar - larvă mare",
  "larva_number_medium": "stadiul larvar - larvă medie",
  "larva_number_small": "stadiul larvar - larvă mică",
  "last_fertilizer_app_date": "b. Data ultimei aplicări de îngrășământ",
  "last_irrigation": "Ultima irigare",
  "lead": "Plumb",
  "Leaf": "Frunză",
  "leaf_moisture_status": "Stare umiditate frunză",
  "LECHUGA": "SALATĂ",
  "LECHUGA ICEBERG": "SALATĂ ICEBERG",
  "leptosphaeria_sacchari_incidence": "leptosphaeria sacchari \"pată inelară\" - incidență",
  "leptosphaeria_sacchari_severity": "leptosphaeria sacchari \"pată inelară\" - severitate",
  "leptosphaeria_sacchari_total_infected_leafs": "leptosphaeria sacchari \"pată inelară\" - total frunze infectate",
  "LIMON": "LĂMÂIE",
  "linear_meters": "02.Metri liniari",
  "loam": "Lut",
  "low irrigation": "Stres hidric scăzut",
  "lower level": "Nivel inferior",
  "lowered_unload": "03.Scobit în descărcare",
  "MACABEU": "MACABEU",
  "MACABEU VIURA": "MACABEU VIURA",
  "magnesium": "Magneziu",
  "magnesium_saturation": "Saturație de Magneziu",
  "MALVASIA AROMATICA": "MALVASIA AROMATICĂ",
  "MALVASIA DE SITGES": "MALVASIA DE SITGES",
  "MALVASIA GROSSA": "MALVASIA GROSSA",
  "MANDARINA": "MANDARINĂ",
  "manganese": "Mangan",
  "MANZANA": "MĂR",
  "mean level": "Nivel mediu",
  "medium irrigation": "Stres hidric mediu",
  "medium_moisture_saturation": "Saturație Medie de Umiditate",
  "MELOCOTON": "PIERSICĂ",
  "MELON": "PEPENE",
  "mercury": "Mercur",
  "MERLOT": "MERLOT",
  "Metric": "Metric",
  "microorganisms_larva": "larve microorganisme",
  "mite_grade": "grad acarieni",
  "mite_infested": "infestat cu acarieni",
  "mite_infested_leafs": "frunze infestate cu acarieni",
  "moisture_status": "07.Stare umiditate",
  "molybdenum": "Molibden",
  "MONTONEC": "MONTONEC",
  "MONTONEGA": "MONTONEGA",
  "MOSCATELL DE ALEXANDRIA": "MOSCATELL DE ALEXANDRIA",
  "MOSCATELL ROMA": "MOSCATELL ROMA",
  "NARANJA": "PORTOCALĂ",
  "NDRE": "NDRE",
  "NDVI": "NDVI",
  "NECATRINA": "NECATRINA",
  "NECTARINA": "NECTARINĂ",
  "nickel": "Nichel",
  "Nitrogen": "Azot",
  "Nitrógeno Foliar": "Azot foliar",
  "nitrógeno-clorofila (ndre)": "Azot-Clorofilă (NDRE)",
  "Nitrógeno-Clorofila (NDRE)": "Azot-Clorofilă (NDRE)",
  "No crop or too dry": "Fără cultură sau prea uscat",
  "NO CULTIVO": "FĂRĂ CULTURĂ",
  "No hay datos": "Nu există date",
  "NOGAL": "NUC",
  "notification_to_the_farmer_high_n_index": "g. Notificare către fermier: Index N ridicat",
  "Observations": "Observații",
  "OLIVO": "MĂSLIN",
  "opogona_larva_number": "opogona - număr larve",
  "organic_material": "Material organic",
  "organic_oxidizable_carbon": "Carbon Organic Oxidabil",
  "PANSA BLANCA": "PANSA ALBĂ",
  "PANSAL": "PANSAL",
  "PARAGUAYO": "PARAGUAYAN",
  "PARELLADA": "PARELLADA",
  "PATATA": "CARTOF",
  "pattern": "model",
  "PERA": "PARĂ",
  "percent_disease": "Procent boală",
  "perkinsiella_345_instar_nymph": "perkinsiella - nimfe 3-4-5 instar",
  "perkinsiella_adult": "perkinsiella - adulți",
  "perkinsiella_controller_coccinelidos": "perkinsiella - controlori - coccinellide",
  "perkinsiella_controller_crysopas": "perkinsiella - controlori - crisope",
  "perkinsiella_controller_spiders": "perkinsiella - controlori - păianjeni",
  "perkinsiella_controller_tytthus_parviceps": "perkinsiella - controlori - tytthus parviceps",
  "perkinsiella_controller_zelus": "perkinsiella - controlori - zelus",
  "perkinsiella_death_adult": "perkinsiella - adulți morți",
  "perkinsiella_death_nymph": "perkinsiella - nimfe moarte",
  "petiole_sampling": "i. Eșantionare pețiol",
  "ph": "Ph",
  "pheromone_trap_diatraea_adults_number": "capcană feromoni - adulți diatraea",
  "pheromone_trap_installation_date": "capcană feromoni - data instalării",
  "pheromone_trap_number": "capcană feromoni - capcane",
  "pheromone_trap_other_butterfly": "capcană feromoni - alte fluturi",
  "pheromone_trap_pheromone_change": "capcană feromoni - schimbare feromon",
  "phosphorus": "Fosfor",
  "PIMIENTO": "ARDEI",
  "PINO": "PIN",
  "PINOT NOIR": "PINOT NOIR",
  "PISTACHO": "FISTIC",
  "Pivot": "Pivot",
  "Plague": "Ciumă",
  "plague_level": "09.Nivel Ciumă",
  "Plant": "Plantă",
  "Plant Density": "Densitate Plantă",
  "plantation_date": "Data plantării",
  "plants_per_surface_unit": "Plante pe unitatea de suprafață",
  "poda": "Tăiere",
  "pol": "Pol",
  "POMELO": "POMELO",
  "POMELOS": "POMELOS",
  "potassium": "Potasiu",
  "potassium_saturation": "Saturație de Potasiu",
  "powdery_mildew_afa_perc": "Făinare - AFA(%)",
  "powdery_mildew_perc_of_affected_plants": "Făinare - % de plante afectate (INCIDENȚĂ)",
  "powdery_mildew_treatment": "Făinare - Tratament",
  "Production": "Producție",
  "pseudomonas_rubrilineans_stem_number": "dungă roșie - total tulpini",
  "pseudomonas_rubrilineans_stem_number_affected": "dungă roșie - tulpini afectate",
  "pseudomonas_rubrilineans_stem_number_with_symptoms": "dungă roșie - tulpini cu simptome",
  "puccinia_melanocephala_accumulated_scale": "rugină - scară cumulată",
  "puccinia_melanocephala_infestation": "rugină-infestare",
  "puccinia_melanocephala_infested_leafs": "rugină-infestate frunze",
  "puccinia_melanocephala_points": "rugină - puncte",
  "puccinia_melanocephala_total_leafs": "rugină - total frunze",
  "puccinia_sp_incidence": "puccinia sp \"roya\" - incidență",
  "puccinia_sp_severity": "puccinia sp \"roya\" - severitate",
  "puccinia_sp_total_infected_leafs": "puccinia sp \"roya\" - total frunze infectate",
  "PUERRO": "PRĂZ",
  "Quality": "Calitate",
  "QUINOA": "QUINOA",
  "RAIGRAS": "RAIGRAS",
  "real_leaves": "frunze reale",
  "REMOLACHA": "SFECLE",
  "REMOLACHA AZUCARERA": "SFECLE DE ZAHĂR",
  "resowing_surface": "Suprafață resămânțare",
  "result": "rezultat",
  "riego": "Irigare",
  "rot10roots_complete": "03.Putregai 10 rădăcini - Complet",
  "rot10roots_nothing": "01.Putregai 10 rădăcini - Nimic",
  "rot10roots_partial": "02.Putregai 10 rădăcini - Parțial",
  "rust_10_25_afa": "Rugina - 10-25 % AFA",
  "rust_100_afa": "Rugina -N- 100 % AFA. Frunzișul original complet distrus",
  "rust_25_35_afa": "Rugina -J- 25-35 % AFA. 1/4 -1/3 din frunziș este grav afectat",
  "rust_3_10_afa": "Rugina -I- 3-10 % AFA",
  "rust_35_50_afa": "Rugina -K- 35-50 % AFA.  1/3 - 1/2 din frunziș este grav afectat",
  "rust_50_75_afa": "Rugina -L- 50-75 % AFA. Jumătate din frunziș este grav afectat",
  "rust_75_90_afa": "Rugina -M- 75-90 % AFA. Tot frunzișul este grav afectat",
  "rust_healthy_plant": "Rugina -A- Plantă sănătoasă",
  "rust_isolated_spots_on_some_leaves_1_9_perc": "Rugina -B- Pete izolate pe unele frunze. 1-9 (%SL)",
  "rust_isolated_spots_on_some_leaves_10_19_perc": "Rugina -C- Pete izolate pe unele frunze. 10-19 (%SL)",
  "rust_isolated_spots_on_some_leaves_20_29_perc": "Rugina -D- Pete izolate pe unele frunze. 20-29 (%SL)",
  "rust_isolated_spots_on_some_leaves_30_50_perc": "Rugina -E- Pete izolate pe unele frunze. 30-50 (%SL)",
  "rust_regrowth_restart": "Rugina -O- Începutul regenerării. Adăugați 0,5 puncte pentru fiecare săptămână până la recoltare",
  "rust_spots_leaves_100_300": "Rugina -H- Frunze cu 100-300 pete",
  "rust_spots_leaves_20_100": "Rugina -G- 20-100 pete pe frunză pe majoritatea frunzelor",
  "rust_spots_leaves_50": "Rugina -F- >50% SL și <20 pete pe frunză pe majoritatea frunzelor",
  "rust_treatment": "Rugina -p- Tratament",
  "sac": "Sac",
  "Safety": "Siguranță",
  "sample_color": "05.Culoare eșantion",
  "sand": "Nisip",
  "SAUVIGNON BLANC": "SAUVIGNON BLANC",
  "severity": "Severitate",
  "soca_width": "04.Număr de rădăcini",
  "sodium": "Sodiu",
  "sodium_saturation": "Saturație de Sodiu",
  "Soil": "Sol",
  "soil_moisture_status": "Stare umiditate sol",
  "SOJA": "SOIA",
  "sprout_dead": "lăstar mort",
  "sprout_total": "total lăstari",
  "starch": "Amidon",
  "stems_evaluated": "tulpini evaluate",
  "sulfur": "Sulf",
  "Superficial": "Superficial",
  "SYRAH": "SYRAH",
  "TABACO": "TUTUN",
  "TE": "CEAI",
  "TEA": "TOIAG",
  "TEMPRANILLO": "TEMPRANILLO",
  "TOMATE": "ROȘIE",
  "total_arsenic": "Ars Total",
  "total_cadmium": "Cadmiu Total",
  "TOURIGA NACIONAL": "TOURIGA NAȚIONALĂ",
  "TRIGO": "GRÂU",
  "TRIGO BLANDO": "GRÂU MOALE",
  "TRIGO DURO": "GRÂU DUR",
  "TRITICALE": "TRITICALE",
  "type_disease": "Tip boală",
  "ustilago_scitaminea_infection": "ustilago scitaminea \"cărbune\" - infecție",
  "ustilago_scitaminea_stem_affected": "ustilago scitaminea \"cărbune\" - total tulpini bolnave",
  "ustilago_scitaminea_stem_symptoms": "ustilago scitaminea \"cărbune\" - tulpini cu simptome",
  "ustilago_scitaminea_stem_total": "ustilago scitaminea \"cărbune\" - tulpini totale",
  "ustilago_scitaminea_stem_whip": "ustilago scitaminea \"cărbune\" - tulpini bici",
  "UVA": "STRUGURE",
  "UVA DE MESA": "STRUGURI DE MASĂ",
  "Variabilidad": "Variabilitate",
  "Variabilidad (NDVI-LUVI)": "Variabilitate (NDVI-LUVI)",
  "VERDEJO": "VERDEJO",
  "very high irrigation": "Stres hidric foarte ridicat",
  "very low irrigation": "Stres hidric foarte scăzut",
  "VIGNE": "VIGNE",
  "Vigor Vegetativo (NDVI-Drone)": "Vigoare vegetativă (NDVI-Drone)",
  "Vigor Vegetativo (NDVI-LUVI)": "Vigoare vegetativă (NDVI-LUVI)",
  "vigor vegetativo (ndvi) - vigor alto": "Vigoare vegetativă (NDVI) - Vigoare ridicată",
  "VIÑA": "VIE",
  "VIOGNIER": "VIOGNIER",
  "virgin_female_georeferenced_trap_diatraea_adult_total": "capcană femeie virgină - total adulți diatraea",
  "virgin_female_georeferenced_trap_female_change": "capcană femeie virgină - schimbare femeie",
  "virgin_female_georeferenced_trap_other_butterfly": "capcană femeie virgină - alte fluturi",
  "virgin_female_georeferenced_trap_point": "capcană femeie virgină - punct capcană",
  "virgin_female_trap_diatraea_adult_number": "capcană femeie virgină - adulți diatraea",
  "virgin_female_trap_female_change": "capcană femeie virgină - schimbare femeie",
  "virgin_female_trap_installation_date": "capcană femeie virgină - data instalării",
  "virgin_female_trap_number": "capcană femeie virgină - capcane",
  "virgin_female_trap_other_butterfly": "capcană femeie virgină - alte fluturi",
  "VIURA": "VIURA",
  "weed_unload": "01. Buruieni (descărcare)",
  "Weeds": "Buruieni",
  "weight_kg": "06.Greutate eșantion",
  "weight_100_beans": "Plante de 100 de fasole",
  "whey": "Zer",
  "without_footwear": "01.Fără Încălțăminte",
  "without_gloves": "04.Fără Mănuși",
  "without_vest": "02.Fără Vestă",
  "XARELLO": "XARELLO",
  "yellowness_mild": "Gălbuie - Atac ușor (plante slabe)",
  "yellowness_moderate": "Gălbuie - Atac moderat (stative)",
  "yellowness_strong": "Galben - Atac puternic (parcelă complet afectată)",
  "yellowness_without_symptoms": "Îngălbenire - Fără simptome",
  "zinc": "Zin"
}
